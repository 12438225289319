export const URLS = {
  HOME: "/",
  CARDS: "/app/card/cards",
  Overview: "/app/card/overview",
  Cards: "/app/card/cards",
  Transactions: "/app/card/transactions",
  Earnings: "/app/card/earnings",
  Settlements: "/app/card/settlements",
  ANALYTICS: "/app/card/analytics",
  ACCOUNTS: "/app/card/accounts",
  TRANSACTIONS: "/app/card/transactions",
  SETTINGS: "/app/card/settings",
  TRANSFERS: "/app/card/transfers",
  CHARGEBACK: "/app/card/chargeback",
};

export default URLS;
