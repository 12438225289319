import { Get, Post, Put } from "apiServices/apiHandler.service";
import {
  accountServiceUrl,
  cardsApi,
  serviceManagerUrl,
} from "apiServices/config.service";
import { createAsyncAction } from "redux-promise-middleware-actions";

export const ActionTypes = {
  ONBOARD_CURRENCY: "ONBOARD_CURRENCY",
  CURRENCY_LIST: "CURRENCY_LIST",
  SUPPORTED_CURRENCIES: "SUPPORTED_CURRENCIES",
  FETCH_REPORTS: "FETCH_REPORTS",
  EARNINGS: "EARNINGS",
  SETTLEMENTS: "SETTLEMENTS",
  CHECK_MERCHANT_STATUS: "CHECK_MERCHANT_STATUS",
  GET_ALL_CARDS: "GET_ALL_CARDS",
  GET_ALL_CARD_TRANSACTIONS: "GET_ALL_CARD_TRANSACTIONS",
  GET_CARD_TRANSACTIONS: "GET_CARD_TRANSACTIONS",
  GET_ALL_CURRENCY: "GET_ALL_CURRENCY",
  GET_CARD_ONBOARDED_CURRENCIES: "GET_CARD_ONBOARDED_CURRENCIES",
  CREATE_CARD: "CREATE_CARD",
  GET_CARD_TYPES: "GET_CARD_TYPES",
  GET_ACCOUNT_TYPES: "GET_ACCOUNT_TYPES",
  GET_SERVICE_MANAGER_COUNTRIES: "GET_SERVICE_MANAGER_COUNTRIES",
  TOPUP_CARD: "TOPUP_CARD",
  SETUP_LIMIT: "SETUP_LIMIT",
  GET_CARD_BY_ID: "GET_CARD_BY_ID",
  GET_CARD_BALANCE_BY_ID: "GET_CARD_BALANCE_BY_ID",
  GET_WALLETS: "GET_WALLETS",
};

export const fetchCurrencies = createAsyncAction(
  ActionTypes.SUPPORTED_CURRENCIES,
  async () => {
    return await Get(
      `v1/Currency/wallet?pageSize=1000&pageNumber=1&isAscending=false`,
      serviceManagerUrl
    );
  }
);

export const fetchReports = createAsyncAction(
  ActionTypes.FETCH_REPORTS,
  async ({ currency, startDate, endDate }) => {
    return await Get(
      `v1/Merchant/summary/${currency}${
        startDate ? "?StartDate=" + startDate : ""
      }${endDate ? "&EndDate=" + endDate : ""}`
    );
  }
);

export const fetchEarnings = createAsyncAction(
  ActionTypes.EARNINGS,
  async ({
    currency,
    type,
    startDate,
    endDate,
    pageSize = 10,
    pageNumber,
    keyword,
  }) => {
    return await Get(
      `v1/Merchant/getearnings/${currency}?pageSize=${pageSize}${
        pageNumber ? "&pageNumber=" + pageNumber : ""
      }${type ? "&type=" + type : ""}${
        startDate ? "&StartDate=" + startDate : ""
      }${endDate ? "&EndDate=" + endDate : ""}${
        keyword ? "&keyword=" + keyword : ""
      }`
    );
  }
);

export const fetchSettlements = createAsyncAction(
  ActionTypes.SETTLEMENTS,
  async ({
    currency,
    type,
    startDate,
    endDate,
    pageSize = 10,
    pageNumber,
    keyword,
  }) => {
    return await Get(
      `v1/Merchant/getsettlement/${currency}?pageSize=${pageSize}${
        pageNumber ? "&pageNumber=" + pageNumber : ""
      }${type ? "&type=" + type : ""}${
        startDate ? "&StartDate=" + startDate : ""
      }${endDate ? "&EndDate=" + endDate : ""}${
        keyword ? "&keyword=" + keyword : ""
      }`
    );
  }
);

export const checkMerchantStatus = createAsyncAction(
  ActionTypes.CHECK_MERCHANT_STATUS,
  async () => {
    return await Get("v1/Merchant/getmerchantstatus");
  }
);
export const onboardCurrency = createAsyncAction(
  ActionTypes.ONBOARD_CURRENCY,
  async (data) => {
    return await Post(
      `v1/Merchant/cards/onboard?Currency=${data.currency}`,
      data
    );
  }
);

export const fetchCurrencyList = createAsyncAction(
  ActionTypes.CURRENCY_LIST,
  async () => {
    return await Get(`v1/Merchant/merchantconfig/getlist`);
  }
);

export const getAllCardsReport = createAsyncAction(
  ActionTypes.GET_ALL_CARDS,
  async (
    currencyCode,
    pageNumber: number = 1,
    pageSize: number = 10,
    IsAscending = false,
    keyword: string = ""
  ) => {
    return await Get(
      `v1/getcardsreport/${currencyCode}?pageNumber=${pageNumber}&pageSize=${pageSize}&isAscending=${IsAscending}&keyword=${keyword}`,
      cardsApi
    );
  }
);

export const getAllCurrencyConfigs = createAsyncAction(
  ActionTypes.GET_ALL_CURRENCY,
  async (
    pageNumber: number = 1,
    pageSize: number = 10,
    IsAscending: boolean = false
  ) => {
    return await Get(
      `v1/Currency/wallet?pageSize=1000&pageNumber=1&isAscending=false`,
      serviceManagerUrl
    );
  }
);

export const getCardTransactions = createAsyncAction(
  ActionTypes.GET_CARD_TRANSACTIONS,
  async ({
    currency,
    keyword,
    pageNumber = 1,
    pageSize = 10,
    IsAscending = false,
    startDate,
    endDate,
  }: {
    currency: string;
    pageNumber?: number;
    pageSize?: number;
    IsAscending?: boolean;
    startDate: Date | string;
    endDate: Date | string;
    keyword?: string;
  }) => {
    return await Get(
      `v1/Merchant/getmerchanttransactions?pageNumber=${pageNumber}&pageSize=${pageSize}${
        currency ? "&currency=" + currency : ""
      }${keyword ? "&keyword=" + keyword : ""}${
        startDate ? "&StartDate=" + startDate : ""
      }${endDate ? "&EndDate=" + endDate : ""}&isAscending=${IsAscending}`
    );
  }
);

export const getOnboardedCurrencies = createAsyncAction(
  ActionTypes.GET_CARD_ONBOARDED_CURRENCIES,
  async (
    pageNumber: number = 1,
    pageSize: number = 10,
    IsAscending: boolean = false,
    keyword?: string
  ) => {
    return await Get(`v1/Merchant/merchantconfig/getlist`);
  }
);

export const mutateCardCreate = createAsyncAction(
  ActionTypes.CREATE_CARD,
  async (payload) => {
    return await Post(`v1/Merchant/create-card`, payload);
  }
);

export const fetchCardTypes = createAsyncAction(
  ActionTypes.GET_CARD_TYPES,
  async () => {
    return await Get(`v1/Merchant/get-card-types`);
  }
);

export const fetchAccountTypes = createAsyncAction(
  ActionTypes.GET_ACCOUNT_TYPES,
  async () => {
    return await Get(`v1/Merchant/get-ledger-types`);
  }
);

export const fetchServiceManagerCountries = createAsyncAction(
  ActionTypes.GET_SERVICE_MANAGER_COUNTRIES,
  async () => {
    return await Get(
      `v1/Country/GetAll?pageSize=300&pageNumber=1&isAscending=false`,
      serviceManagerUrl
    );
  }
);

export const topupCard = createAsyncAction(
  ActionTypes.TOPUP_CARD,
  async (payload) => {
    return await Post(`v1/add-funds`, payload, cardsApi);
  }
);

export const setupLimit = createAsyncAction(
  ActionTypes.SETUP_LIMIT,
  async (payload) => {
    return await Put(`v1/update-limit`, payload, cardsApi);
  }
);

export const fetchCardById = createAsyncAction(
  ActionTypes.GET_CARD_BY_ID,
  async (id) => {
    return await Get(`v1/get-card-details/${id}`, cardsApi);
  }
);

export const getCardBalanceById = createAsyncAction(
  ActionTypes.GET_CARD_BALANCE_BY_ID,
  async (id) => {
    return await Get(`v1/get-card-balance/${id}`, cardsApi);
  }
);

export const fetchWallets = createAsyncAction(
  ActionTypes.GET_WALLETS,
  async () => {
    return await Get(`v1/corporateWallet/wallets/client`, accountServiceUrl);
  }
);
