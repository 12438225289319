import React, { ReactNode } from "react";
import "./accountDetails.scss";

export const Label = ({
  children,
  className,
}: {
  children: ReactNode;
  className?: string;
}) => {
  return (
    <div className={`label-item font-500 ${className ? className : ""}`}>
      {children}
    </div>
  );
};

const Row = ({ label, value }: { label: ReactNode; value: ReactNode }) => (
  <div className="d-flex align-items-center justify-content-between">
    <Label>{label}</Label>
    <Label className="font-600 dark">{value}</Label>
  </div>
);

export default Row;
