import { ReactComponent as Arrow } from "assets/images/arrowDown.svg";
import { ReactComponent as FilterIcon } from "assets/images/filter.svg";
import { ReactComponent as Totalactive } from "assets/images/totalactivecard.svg";
import { ReactComponent as Totalblocked } from "assets/images/totalblockedcard.svg";
import { ReactComponent as Totalcards } from "assets/images/totalcardIssued.svg";
import { ReactComponent as Totalfrozen } from "assets/images/totalfrozencard.svg";
import StatsBox from "components/statsBox/statsBox";
import _debounce from "lodash.debounce";
import { useEffect, useState } from "react";
import { Table } from "reactstrap";
import TablePagination from "shared/tablePagination/tablePagination";

import CardsModal from "components/CardsModal/CardsModal";
import { CardRecordType, ICurrencyFilter } from "interfaces";
import { useDispatch, useSelector } from "react-redux";
import {
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
} from "reactstrap";
import {
  fetchCurrencies,
  fetchCurrencyList,
  getAllCardsReport,
  getAllCurrencyConfigs,
} from "redux/actions/cards.action";
import EmptyState from "shared/empty/empty";
import "./Analytics.scss";
import { Button } from "vgg-ui-react/dist/components/Core";
import CreateCardModal from "components/CreateCardModal/CreateCardModal";
import { maskNumber } from "helpers/maskNumber";
import ActionDropdown from "components/ActionDropdown/ActionDropdown";
import TopupModal from "components/TopupModal/TopupModal";
import SetCardLimit from "components/SetCardLimit/SetCardLimit";
import { PageLoader } from "components/loader/Loader";
import SuccessModal from "shared/SuccessModal/SuccessModal";
import { getSuccessMsg } from "helpers/utils";

const dummyData = {
  filter: [
    { name: "Active" },
    { name: "Blocked" },
    { name: "Frozen" },
    { name: "Date Created" },
  ],
};
const Analytics = ({
  currencyList,
  fetchingCurrencyList,
}: {
  currencyList: ICurrencyFilter[];
  fetchingCurrencyList: boolean;
}) => {
  const dispatch = useDispatch();
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [filterDropdownOpen, setFilterDropdownOpen] = useState(false);
  const [mockData] = useState(dummyData);
  const [showSuccess, setShowSuccess] = useState({ open: false, key: "" });
  const [currentPage, setCurrentPage] = useState(1);
  const [openCardsModal, setOpenCardsModal] = useState(false);
  const [openCreateModal, setOpenCreateModal] = useState(false);
  const [cardData, setCardData] = useState({});
  const [pageSize] = useState(10);
  const [showCardLimitModal, setShowCardLimitModal] = useState(false);
  const [showTopupModal, setShowTopupModal] = useState(false);
  const [rowData, setRowData] = useState<CardRecordType | null>(null);

  const { fetchingCards, cardsPayload } = useSelector(({ cards }: any) => {
    return {
      fetchingCards: cards?.fetchingCards,
      cardsPayload: cards?.cardsPayload,
      fetchingCurrencies: cards?.fetchingCurrencies,
      currenciesPayload: cards?.currenciesPayload,
    };
  });
  const [selectedCurrency, setSelectedCurrency] = useState<any>(null);

  const searchUsers = _debounce(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      dispatch(
        getAllCardsReport(
          selectedCurrency,
          currentPage,
          pageSize,
          false,
          event.target.value
        )
      );
    },
    1000
  );

  const overviewData = [
    {
      title: "Total Card Issued",
      value: cardsPayload?.responseData?.totalCardsIssued,
      icon: <Totalcards />,
      color: "#EAFBF1",
    },
    {
      title: "Total Active Cards",
      value: cardsPayload?.responseData?.totalActiveCards,
      icon: <Totalactive />,
      color: "#FDF2E7",
    },
    {
      title: "Total Blocked Cards",
      value: cardsPayload?.responseData?.totalBlockedCards,
      icon: <Totalblocked />,
      color: "#E8EFFD",
    },
    {
      title: "Total Frozen Cards",
      value: cardsPayload?.responseData?.totalFrozenCards,
      icon: <Totalfrozen />,
      color: "#FCE9E9",
    },
  ];

  useEffect(() => {
    dispatch(getAllCurrencyConfigs());
    if (!currencyList && !fetchingCurrencyList) {
      dispatch(fetchCurrencyList());
    }
    dispatch(fetchCurrencies());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!fetchingCurrencyList && currencyList && !selectedCurrency) {
      const curr = currencyList.filter(
        (v: ICurrencyFilter) => v.status.toLowerCase() === "approved"
      );
      if (curr.length > 0) {
        setSelectedCurrency(curr[0]?.currency);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fetchingCurrencyList, currencyList, selectedCurrency]);

  // when changing current page refetch cards
  useEffect(() => {
    if (!selectedCurrency) return;
    dispatch(getAllCardsReport(selectedCurrency, currentPage, pageSize, false));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPage, selectedCurrency]);

  const onOpenViewModal = (data: any) => {
    setOpenCardsModal(true);
    setCardData(data);
  };

  const getCardStatusClass = (status: string) => {
    switch (status.toLowerCase()) {
      case "active":
        return "success";
      case "inactive":
        return "failed";
      default:
        return "";
    }
  };

  const ACTION_OPTIONS = [
    { label: "Top up", value: "topup" },
    { label: "Set card limit", value: "set-limit" },
  ];

  return (
    <>
      <div className="d-flex justify-content-between align-items-center analytics-wrapper">
        <h5 className="fw-bold">Cards</h5>

        <div className="d-flex align-items-center justify-content-end">
          <Dropdown
            isOpen={dropdownOpen}
            toggle={() => setDropdownOpen(!dropdownOpen)}
            direction={"down"}
            className="mr-4"
          >
            <DropdownToggle color="light" className="border mr-19">
              <div className="d-flex justify-content-between align-items-center gap-4">
                {selectedCurrency || "Filter"}
                <Arrow className={`${dropdownOpen && "rotate"}`} />
              </div>
            </DropdownToggle>
            <DropdownMenu
              container={"body"}
              className={"m-2 "}
              style={{ minWidth: "none" }}
            >
              {currencyList
                ?.filter(
                  (v: ICurrencyFilter) => v.status.toLowerCase() === "approved"
                )
                ?.map((payload: ICurrencyFilter, index: number) => (
                  <DropdownItem
                    key={index}
                    onClick={() => setSelectedCurrency(payload.currency)}
                  >
                    <span>{payload.currency}</span>
                  </DropdownItem>
                ))}
            </DropdownMenu>
          </Dropdown>
          <Button
            label="New Card"
            background="blue"
            onClick={() => setOpenCreateModal(true)}
          />
        </div>
      </div>
      <div className="d-flex justify-content-between gap-3 align-items-center my-4 flex-wrap">
        {" "}
        {overviewData?.map((item, index) => (
          <StatsBox
            key={index}
            title={item.title}
            stat={item.value}
            color={item.color}
            icon={item.icon}
          />
        ))}
      </div>

      <div className="analytics-container border rounded">
        <div className="d-flex justify-content-between align-items-center p-3">
          <div className="search-container">
            <input
              type="search"
              placeholder="Search"
              className="form-control search-input mr-2"
              onChange={searchUsers}
            />
            <i className="ri-search-line position-absolute top-1" />
          </div>

          <Dropdown
            isOpen={filterDropdownOpen}
            toggle={() => setFilterDropdownOpen(!filterDropdownOpen)}
          >
            <DropdownToggle color="light" className="border" disabled={true}>
              <div className="d-flex justify-content-between gap-2 align-items-center">
                <FilterIcon />
                <span className="text-secondary">Filter</span>
                <Arrow
                  className={`${filterDropdownOpen && "rotate fade-in"}`}
                />
              </div>
            </DropdownToggle>
            <DropdownMenu container={"body"} className=" mt-2">
              {mockData?.filter?.map((filters, index) => (
                <DropdownItem key={index}>{filters.name}</DropdownItem>
              ))}
            </DropdownMenu>
          </Dropdown>
        </div>

        {fetchingCards ? (
          <PageLoader
            height={50}
            showLoadingText
            primaryText="Loading"
            secondaryText="Please wait..."
          />
        ) : cardsPayload?.responseData?.data?.items.length > 0 ? (
          <>
            <div className="px-3">
              <Table striped borderless responsive>
                <thead>
                  <tr>
                    <td>Card Name</td>
                    <td>Currency</td>
                    {/* <td>Card Balance</td> */}
                    <td>Card Number</td>
                    {/* <td>Card Type</td> */}
                    <td>Status</td>
                    <td>Actions</td>
                    <td></td>
                  </tr>
                </thead>
                <tbody>
                  {cardsPayload?.responseData?.data?.items?.map(
                    (card: any, index: number) => {
                      return (
                        <tr key={index}>
                          <td className="text-capitalize">
                            {card?.nameOnCard.trim().length > 0
                              ? card?.nameOnCard || "-"
                              : "-"}
                          </td>
                          <td>{card.currency}</td>
                          {/* <td>{card.cardBalance ?? "-"}</td> */}
                          <td>{maskNumber(card.maskedPan) || "-"}</td>
                          {/* <td>{card.cardType || "-"}</td> */}
                          <td>
                            <span
                              className={`text-uppercase ${getCardStatusClass(
                                card?.cardStatus || ""
                              )} rounded p-2`}
                            >
                              {card.cardStatus || "-"}
                            </span>
                          </td>
                          {/* <td>
                            {card?.createdAt
                              ? new Date(card.createdAt).toLocaleDateString()
                              : "-"}
                          </td> */}
                          <td className="text-primary cursor-pointer text-capitalize">
                            {" "}
                            {/* add alink  */}
                            <button
                              className="btn text-primary card-action"
                              type="button"
                              onClick={() =>
                                onOpenViewModal({
                                  ...card,
                                  createdAt: new Date(
                                    card.createdAt
                                  ).toLocaleDateString(),
                                })
                              }
                            >
                              View Card
                            </button>
                          </td>
                          <td className="text-primary cursor-pointer text-capitalize">
                            <ActionDropdown
                              data={ACTION_OPTIONS}
                              onItemClick={(val) => {
                                setRowData(card);
                                if (val.value === ACTION_OPTIONS[0]?.value) {
                                  setShowTopupModal(true);
                                }
                                if (val.value === ACTION_OPTIONS[1]?.value) {
                                  setShowCardLimitModal(true);
                                }
                              }}
                            />
                          </td>
                        </tr>
                      );
                    }
                  )}
                </tbody>
              </Table>
            </div>
            <div className="border-top px-3">
              <TablePagination
                totalCount={cardsPayload?.responseData?.data?.count}
                currentPage={cardsPayload?.responseData?.data?.pageNumber}
                pageSize={cardsPayload?.responseData?.data?.pageSize}
                onPageChange={(page: number) => setCurrentPage(page)}
                totalPages={cardsPayload?.responseData?.data?.pages.toString()}
              />
            </div>
          </>
        ) : (
          <EmptyState
            btnName="Reload"
            showIcon
            remixClass="ri-file-damage-line"
            description="Your table data would appear here"
            handler={() =>
              dispatch(
                getAllCardsReport(
                  selectedCurrency,
                  currentPage,
                  pageSize,
                  false
                )
              )
            }
          />
        )}
      </div>
      {openCardsModal && (
        <CardsModal
          open={openCardsModal}
          toggle={() => setOpenCardsModal(!openCardsModal)}
          data={cardData}
        />
      )}
      {openCreateModal && (
        <CreateCardModal
          open={openCreateModal}
          toggle={() => setOpenCreateModal(!openCreateModal)}
          onSuccess={() =>
            dispatch(
              getAllCardsReport(selectedCurrency, currentPage, pageSize, false)
            )
          }
        />
      )}
      {showTopupModal && (
        <TopupModal
          open={showTopupModal}
          toggle={() => {
            setShowTopupModal(false);
            setRowData(null);
          }}
          onSuccess={(data) => {
            setShowSuccess({ open: true, key: "topup" });
          }}
          cardId={rowData?.cardId}
          debitWallet={rowData?.currency}
          cardPan={maskNumber(rowData?.maskedPan ?? "")}
        />
      )}
      {showCardLimitModal && (
        <SetCardLimit
          open={showCardLimitModal}
          toggle={() => {
            setShowCardLimitModal(false);
            setRowData(null);
          }}
          onSuccess={() => setShowSuccess({ open: true, key: "limit" })}
          cardId={rowData?.cardId}
          limitEnabled={!!rowData?.enableLimit}
        />
      )}

      {showSuccess && (
        <SuccessModal
          open={showSuccess.open}
          onClose={() => setShowSuccess({ open: false, key: "" })}
          btnText="Close"
          description={getSuccessMsg(showSuccess.key)}
          onDoneClick={() => {
            setShowSuccess({ open: false, key: "" });
            setShowCardLimitModal(false);
            setShowTopupModal(false);
            dispatch(
              getAllCardsReport(selectedCurrency, currentPage, pageSize, false)
            );
          }}
        />
      )}
    </>
  );
};

export default Analytics;
