import { memo, useEffect, useMemo, useState } from "react";
import { Modal, ModalHeader, ModalBody, Alert } from "reactstrap";
import { useForm } from "react-hook-form";
import * as Yup from "yup";
import { Typography } from "vgg-ui-react/dist/components/Core";
import { yupResolver } from "@hookform/resolvers/yup";
import StepOne from "./StepOne";
import StepTwo from "./StepTwo";
import "./createCardModal.scss";
import CreatedCardDetail from "./CreatedCardDetail/CreatedCardDetail";
import { useDispatch, useSelector } from "react-redux";
import { PageLoader } from "components/loader/Loader";
import {
  fetchAccountTypes,
  fetchCardTypes,
  fetchCurrencies,
  fetchServiceManagerCountries,
  fetchWallets,
  mutateCardCreate,
} from "redux/actions/cards.action";
import { CountryType, CreatedCardType, CurrencyType } from "interfaces";
import { format } from "date-fns";
import { successMessage } from "redux/actions/notification";
import { VirtualCardData } from "components/VirtualCard/VirtualCard";

const validationSchema = Yup.object().shape({
  cardHolderName: Yup.string()
    .matches(/^[A-Za-z\s]+$/, "Invalid name format")
    .required("Card Holder Name is required!"),
  currency: Yup.string().required("Currency is required!"),
  accountType: Yup.string().required("Account Type is required!"),
  cardType: Yup.string().required("Card Type is required!"),
  country: Yup.string().required("Country is required!"),
  bvn: Yup.string()
    .required("BVN is required!")
    .matches(/^\d+$/, "Invalid character entered")
    .test(
      "len",
      "Input must be exactly 11 characters",
      (val) => val?.length === 11
    ),
  phoneNumber: Yup.string().required("Phone Number is required!"),
  email: Yup.string().email("Invalid email").required("Email is required!"),
  dateOfBirth: Yup.string().required("Date of Birth is required!"),
  // dateOfBirth: Yup.date()
  //   .max(new Date(), "Date of birth cannot be in the future")
  //   .test("age", "Must be at least 18 years old", (value) => {
  //     const eighteenYearsAgo = subYears(new Date(), 18);
  //     return value !== undefined && isBefore(value, eighteenYearsAgo);
  //   })
  //   .required("Date of Birth is required!"),
  firstName: Yup.string().matches(/^[A-Za-z\s]+$/, "Invalid name format"),
  lastName: Yup.string().matches(/^[A-Za-z\s]+$/, "Invalid name format"),
});

export const defaultValues = {
  cardHolderName: "",
  currency: "",
  accountType: "",
  cardType: "",
  country: "",
  debitWallet: "",
  bvn: "",
  phoneNumber: "",
  email: "",
  dateOfBirth: "",
  firstName: "",
  lastName: "",
};

const CreateCardModal: React.FC<{
  open: boolean;
  toggle: () => void;
  onSuccess: () => void;
}> = ({ open, toggle, onSuccess }) => {
  const [step, setStep] = useState(1);
  const [errorMsg, setErrorMsg] = useState("");
  const [submitted, setSubmitted] = useState(false);
  const [createdCard, setCreatedCard] = useState<
    (CreatedCardType & VirtualCardData) | undefined
  >();
  const {
    control,
    register,
    handleSubmit,
    watch,
    trigger,
    formState: { errors, isValid },
  } = useForm({
    resolver: yupResolver(validationSchema) as any,
    defaultValues,
    reValidateMode: "onBlur",
    mode: "onBlur",
  });

  const dispatch = useDispatch();

  const {
    createCardPayload,
    creatingCard,

    cardTypesPayload,
    fetchingCardTypes,

    accountTypesPayload,
    fetchingAccountTypes,

    fetchingCurrencies,
    currenciesPayload,

    countriesPayload,
    fetchingCountries,

    walletsPayload,
    fetchingWallets,
  } = useSelector(({ cards }: { cards: any }) => cards);
  useEffect(() => {
    if (step === 1) {
      if (!accountTypesPayload) {
        dispatch(fetchAccountTypes());
      }
      if (!cardTypesPayload) {
        dispatch(fetchCardTypes());
      }
      if (!currenciesPayload) {
        dispatch(fetchCurrencies());
      }
    }

    if (step === 2) {
      if (!countriesPayload) {
        dispatch(fetchServiceManagerCountries());
      }
      if (!walletsPayload) {
        dispatch(fetchWallets());
      }
    }
  }, [
    accountTypesPayload,
    currenciesPayload,
    cardTypesPayload,
    countriesPayload,
    walletsPayload,
    step,
    dispatch,
  ]);

  const onSubmit = (payload: typeof defaultValues) => {
    setSubmitted(true);
    dispatch(
      mutateCardCreate({
        ...payload,
        dateOfBirth: payload.dateOfBirth
          ? format(new Date(payload.dateOfBirth), "yyyy-MM-dd")
          : "",
      })
    );
  };

  useEffect(() => {
    if (!creatingCard && submitted) {
      setSubmitted(false);

      if (createCardPayload?.data?.requestSuccessful) {
        setStep(3);
        setCreatedCard(createCardPayload?.data?.responseData);
        onSuccess();
        dispatch(
          successMessage(
            createCardPayload?.data?.message ?? "Card created successfully"
          )
        );
      } else {
        setCreatedCard(undefined);
        setErrorMsg(
          createCardPayload?.data?.message ??
            "Unable to create card. Please try again"
        );
      }
    }
  }, [creatingCard, createCardPayload]);

  const accountTypeOptions = useMemo(
    () =>
      Object.values(accountTypesPayload?.data?.responseData ?? {}) as string[],
    [accountTypesPayload?.data?.responseData]
  );

  const cardTypeOptions = useMemo(
    () => Object.values(cardTypesPayload?.data?.responseData ?? {}) as string[],
    [cardTypesPayload?.data?.responseData]
  );

  useEffect(() => {
    let timeOut: any;
    if (errorMsg) {
      timeOut = setTimeout(() => {
        setErrorMsg("");
      }, 8000);
    }

    return () => {
      if (timeOut) {
        clearTimeout(timeOut);
      }
    };
  }, [errorMsg]);

  return (
    <Modal isOpen={open} toggle={toggle} className="max-w-400" backdrop="static" centered>
      {!creatingCard && (
        <ModalHeader className="ms-0 align-items-start" toggle={toggle}>
          <div className="sp-modal__title">
            {step === 3 ? "Card Created" : `New Card(${step}/2)`}

            <Typography
              typographyVariant="body-text2"
              text="Generate a new card for users."
              className="sp-modal__subtitle"
            />
          </div>
        </ModalHeader>
      )}

      <ModalBody className="sp-modal__body">
        <Alert
          color="danger"
          isOpen={!!errorMsg}
          toggle={() => setErrorMsg("")}
        >
          <span className="small">{errorMsg}</span>
        </Alert>
        {creatingCard ? (
          <PageLoader
            height={50}
            showLoadingText
            primaryText="Submitting information"
            secondaryText="Please wait..."
          />
        ) : (
          <>
            {step === 3 && <CreatedCardDetail data={createdCard} />}
            <form onSubmit={handleSubmit(onSubmit)}>
              {step === 1 && (
                <StepOne
                  trigger={trigger}
                  control={control as any}
                  currencyOptions={
                    currenciesPayload?.data?.responseData?.items?.map(
                      (v: CurrencyType) => ({
                        label: v.name,
                        value: v.currencyCode,
                      })
                    ) ?? []
                  }
                  fetchingCurrencies={fetchingCurrencies}
                  fetchingAccountTypes={fetchingAccountTypes}
                  fetchingCardTypes={fetchingCardTypes}
                  accountTypeOptions={accountTypeOptions?.map((v: string) => ({
                    label: v,
                    value: v,
                  }))}
                  cardTypeOptions={cardTypeOptions?.map((v: string) => ({
                    label: v,
                    value: v,
                  }))}
                  register={register}
                  errors={errors}
                  onNextClick={() => setStep(2)}
                  watch={watch}
                />
              )}
              {step === 2 && (
                <StepTwo
                  control={control as any}
                  currencyOptions={[]}
                  accountTypeOptions={[]}
                  countryOptions={countriesPayload?.data?.responseData?.items?.map(
                    (v: CountryType) => ({ label: v.name, value: v.isO3 })
                  )}
                  walletOptions={walletsPayload?.data?.data?.map((v: any) => ({
                    label: v.currency,
                    value: v.currency,
                  }))}
                  register={register}
                  errors={errors}
                  onGoBack={() => setStep(1)}
                  watch={watch}
                  fetchingCountries={fetchingCountries}
                  fetchingWallets={fetchingWallets}
                  isValid={isValid}
                />
              )}
            </form>
          </>
        )}
      </ModalBody>
    </Modal>
  );
};

export default memo(CreateCardModal);
