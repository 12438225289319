import React from "react";
import Shimmer from "react-shimmer-effect";
import { Spinner } from "reactstrap";
import "./loader.scss";

export default function Loader() {
  return <div>Retriving Authentication State.....</div>;
}

export const ShimmerTableRowLoader = ({
  colSpan,
  rows,
  isChild,
  isGrandChild,
  columnWidths,
}: {
  colSpan?: number;
  rows?: number;
  isChild?: Boolean;
  isGrandChild?: boolean;
  columnWidths?: number[];
}) => {
  const rowList = [];
  for (let i = 1; i <= (rows || 5); i++) {
    rowList.push(i);
  }

  return (
    <>
      {rowList.map((row) => (
        <tr
          key={row}
          className={`cl-margin-padding ${isChild ? "child-row" : ""} ${
            isGrandChild ? "grand-child-row" : ""
          }`}
        >
          {columnWidths?.map((columnWidth, idx) => (
            <td
              width={`${columnWidth}%`}
              key={`${columnWidth}${idx}`}
              style={{ display: "table-cell" }}
            >
              <Shimmer>
                <div className="shimmer-line" data-testid="td-line-loader" />
              </Shimmer>
            </td>
          ))}
        </tr>
      ))}
    </>
  );
};

export const LoadingTableContent = ({
  colSpan,
  rows,
  isChild,
  isGrandChild,
}: {
  colSpan: number;
  rows?: number;
  isChild?: Boolean;
  isGrandChild?: boolean;
}) => {
  return (
    <ShimmerTableRowLoader
      rows={rows || 10}
      columnWidths={new Array(parseInt(colSpan.toString())).fill(
        100 / +colSpan
      )}
      isChild={isChild}
      isGrandChild={isGrandChild}
      // columnWidths={new Array(parseInt(colSpan)).fill(0).map(val => "100%")}
    />
  );
};

export const LoadingDropdownItems = ({
  name,
}: {
  name: string | JSX.Element;
}) => {
  return (
    <span className="loading-dropdown">
      Loading {name} <i className="fa fa-spin fa-spinner"></i>
    </span>
  );
};

export const LineLoader = ({
  lines = 1,
  className,
  ...rest
}: {
  lines?: number;
  className?: string;
}) => {
  const lineNum = [];
  for (let i = 1; i <= lines; i++) {
    lineNum.push(i);
  }
  return (
    <>
      {lineNum.map((n) => (
        <Shimmer key={n}>
          <div
            className={`shimmer-line ${className}`}
            data-testid="line-loader"
            {...rest}
          />
        </Shimmer>
      ))}
    </>
  );
};

export const PageLoader = ({
  height,
  type,
  showLoadingText,
  primaryText = "Loading",
  secondaryText,
}: {
  height?: number;
  type?: "grow" | "spin";
  showLoadingText?: boolean;
  secondaryText?: string;
  primaryText?: string;
}) => (
  <div
    className={`pg_loader d-flex flex-column align-items-center justify-content-center w-100 h--${
      height || 75
    }`}
  >
    <Spinner color="primary" type={type}>
      Loading...
    </Spinner>

    {showLoadingText && <p className="small primary-text">{primaryText}</p>}
    {secondaryText && <h2 className="small secondary-text">{secondaryText}</h2>}
  </div>
);

export const SpinLoader = ({
  color,
  size,
}: {
  color?: string;
  size?: string | number;
}) => {
  return (
    <Spinner color={color || "primary"} size={size || ""}>
      Loading...
    </Spinner>
  );
};
