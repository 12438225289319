import React from "react";
import { useDispatch, useSelector } from "react-redux";
import Dashboard from "pages/dashboard/Dashboard";
import OnboardingPage from "../onboardingPage/OnboardingPage";
import CustomSpinner from "../../components/CustomSpinner/CustomSpinner";
import { checkMerchantStatus } from "redux/actions/cards.action";
import ErrorPage from "components/ErrorPage/ErrorPage";
import AwaitingApprovalPage from "pages/awaitingApprovalPage/AwaitingApprovalPage";

const LandingPage = () => {
  const dispatch = useDispatch();
  const { checkingMerchantStatus, merchantStatus } = useSelector(
    ({ cards }: any) => cards
  );
  React.useEffect(() => {
    dispatch(checkMerchantStatus());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const renderComponent = (onboarded: boolean, active: boolean) => {
    if (onboarded) {
      if (active) {
        return <Dashboard />;
      } else {
        return <AwaitingApprovalPage />;
      }
    } else {
      return <OnboardingPage onboarded={onboarded} />;
    }
  };
  if (checkingMerchantStatus || !merchantStatus) {
    return <CustomSpinner height="75vh" />;
  }

  if (merchantStatus?.data && !merchantStatus?.data.requestSuccessful) {
    return <ErrorPage />;
  }
  if (merchantStatus?.data && merchantStatus?.data.requestSuccessful) {
    return renderComponent(
      merchantStatus?.data?.responseData?.isOnboarded,
      merchantStatus?.data?.responseData?.isOnboardedAndActive
    );
  }
  return <OnboardingPage onboarded={false} />;
};
export default LandingPage;
