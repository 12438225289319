import React from "react";
import "./currenciesHeader.scss"

const CurrenciesHeader = ({loading}: {loading: boolean}) => {
  return (
    <div className='currencies-header'>
      <h1 className='currencies-header__title' >
        {loading ? "Loading..." : "Choose Currency" }
      </h1>
      <h2 className='currencies-header__sub-title'
      >
       {!loading && "See available currencies you can onboard"}
      </h2>
    </div>
  );
};

export default CurrenciesHeader;
