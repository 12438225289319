import React from "react";
import { Modal, ModalBody, ModalHeader } from "reactstrap";
import { Button } from "vgg-ui-react/dist/components/Core";
import checkMark from "../../assets/images/success.svg";
import "./successModal.scss";

export interface ISuccessModalProps {
  selectedCurrency?: string;
  description?: string | JSX.Element;
  statusText?: string | JSX.Element;
  open: boolean;
  onClose: () => void;
  onDoneClick: () => void;
  btnText?: string | JSX.Element;
}

const SuccessModal: React.FC<ISuccessModalProps> = ({
  description,
  statusText,
  open,
  onClose,
  onDoneClick,
  btnText,
  selectedCurrency,
}) => {
  return (
    <Modal
      isOpen={open}
      onClosed={onClose}
      centered
      // backdrop={false}
      className="success-modal-container"
    >
      <ModalHeader toggle={onClose}>
        {/* <BackButton remixIconClass="close-line" label="Go to Products" /> */}
        {/* Onboard Currency */}
      </ModalHeader>
      <ModalBody className="d-flex align-items-center justify-content-between flex-column">
        <div className="d-flex flex-column align-items-center justify-content-center content-wrapper">
          <div className="mb-3">
            <img src={checkMark} alt="" height="100" width="100" />
          </div>
          <div className="mb-3">
            <h3 className="success-title">Successfully submitted!</h3>
          </div>
          <p className="mb-3 success-content">
            {description ||
              "Your onboarding request has been successfully submitted and you’ll get feedback within the next 3 Business days."}
          </p>
          {selectedCurrency && (
            <p className="mb-3 success-content">
              <strong>{selectedCurrency}: </strong>
              {statusText || "Awaiting Approval"}
            </p>
          )}
        </div>
        <Button
          className="w-100"
          label={btnText || "Done"}
          background="blue"
          onClick={onDoneClick}
        />
      </ModalBody>
    </Modal>
  );
};

export default SuccessModal;
