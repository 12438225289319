import React, { FormEvent, useEffect, useState } from "react";
import { Alert, Modal, ModalBody, ModalHeader } from "reactstrap";
import { Button, Typography } from "vgg-ui-react/dist/components/Core";
import { NumericFormat } from "react-number-format";
import "./topupModal.scss";
import { useDispatch, useSelector } from "react-redux";
import { topupCard } from "redux/actions/cards.action";
import { successMessage } from "redux/actions/notification";
import { SpinLoader } from "components/loader/Loader";

const TopupModal: React.FC<{
  open: boolean;
  toggle: () => void;
  cardId?: string;
  debitWallet?: string;
  cardPan?: string;
  onSuccess: (val: any) => void;
}> = ({ open, toggle, cardId, debitWallet, cardPan, onSuccess }) => {
  const dispatch = useDispatch();
  const [errorMsg, setErrorMsg] = useState("");
  const [submitted, setSubmitted] = useState(false);
  const [value, setValue] = useState("");

  const { topupCardPayload, topingCard, topupCardError } = useSelector(
    ({ cards }: { cards: any }) => cards
  );

  const onSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setSubmitted(true);
    dispatch(topupCard({ cardId, debitWallet, amount: value }));
  };

  useEffect(() => {
    let timeOut: any;
    if (errorMsg) {
      timeOut = setTimeout(() => {
        setErrorMsg("");
      }, 8000);
    }

    return () => {
      if (timeOut) {
        clearTimeout(timeOut);
      }
    };
  }, [errorMsg]);

  useEffect(() => {
    if (!topingCard && submitted) {
      if (topupCardError) {
        setErrorMsg(
          topupCardError?.data?.message ??
            "Unable to create card. Please try again"
        );
      } else if (topupCardPayload?.data?.requestSuccessful) {
        toggle();
        onSuccess(topupCardPayload?.data);
        dispatch(
          successMessage(
            topupCardPayload?.data?.message ?? "Card created successfully"
          )
        );
      } else {
        setErrorMsg(
          topupCardPayload?.data?.message ??
            "Unable to create card. Please try again"
        );
      }

      setSubmitted(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    dispatch,
    submitted,
    toggle,
    topingCard,
    topupCardPayload,
    topupCardError,
  ]);

  return (
    <Modal isOpen={open} toggle={toggle} backdrop="static" className="max-w-400" centered>
      <ModalHeader className="ms-0 align-items-start" toggle={toggle}>
        <div className="sp-modal__title">
          Topup Card
          <Typography
            typographyVariant="body-text2"
            text={`Add money ${cardPan ? "to " + cardPan : ""}`}
            className="sp-modal__subtitle"
          />
        </div>
      </ModalHeader>

      <ModalBody className="sp-modal__body">
        <Alert
          color="danger"
          isOpen={!!errorMsg}
          toggle={() => setErrorMsg("")}
        >
          <span className="small">{errorMsg}</span>
        </Alert>
        <div className="instr">
          Ensure you have enough funds in your {debitWallet} wallet{" "}
        </div>
        <form onSubmit={onSubmit} className="mt-4">
          <div>
            <label htmlFor="amount">Amount</label>
            <NumericFormat
              name="amount"
              thousandSeparator
              onValueChange={(e) => {
                setValue(e.value);
              }}
              className="form-control w-100"
            />
          </div>
          <div>
            <Button
              type="submit"
              label={topingCard ? <SpinLoader size="sm" /> : "Topup"}
              background="blue"
              className="w-100 mt-4"
              disabled={!value || topingCard}
              isLoading={topingCard}
            />
          </div>
        </form>
      </ModalBody>
    </Modal>
  );
};

export default TopupModal;
