import { Modal, ModalHeader, ModalBody } from "reactstrap";
import "./transactionsModal.scss";
import { format } from "date-fns";
import { maskNumber } from "helpers/maskNumber";

const TransactionsModal: React.FC<{
  open: boolean;
  toggle: () => void;
  transactionData: any;
}> = ({ open, toggle, transactionData }) => {
  const getModifiedTransactionData: any = () => {
    const newData = {
      cardName: transactionData.cardName,
      currency: transactionData.currency || "-",
      transactionAmount: transactionData.amount,
      maskedPan: maskNumber(transactionData.maskedPan) || "-",
      // time: new Date(transactionData.createdAt).toLocaleDateString() || "-",
      time: transactionData.createdAt
        ? format(new Date(transactionData.createdAt), "h:mmaaa")
        : "-",
      status: transactionData.transactionStatus || "-",
      reference: transactionData.reference || "-",
    };
    return newData;
  };
  return (
    <Modal
      isOpen={open}
      toggle={toggle}
      className="drawer fadeInRight w-480 animated ms-auto"
    >
      <ModalHeader className="border border-bottom" toggle={toggle}>
        <div>Transaction Details</div>
      </ModalHeader>
      <ModalBody>
        {Object.keys(getModifiedTransactionData()).map((key, index) => {
          return (
            <div
              key={index}
              className="border-bottom d-flex flex-column data-card"
            >
              <span className="header text-capitalize">
                {key.split(/(?=[A-Z])/).join(" ")}
              </span>
              <span className="description">
                {getModifiedTransactionData()[key]}
              </span>
            </div>
          );
        })}
      </ModalBody>
    </Modal>
  );
};

export default TransactionsModal;
