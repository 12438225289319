import "./virtualCard.scss";
import { CardRecordType, CreatedCardType } from "interfaces";
import { format } from "date-fns";

import { formatCardPan } from "helpers/utils";

export type VirtualCardData = CardRecordType & CreatedCardType;

const VirtualCard = ({ data }: { data?: VirtualCardData }) => {
  const getMasked = (data: string) => {
    if (!data) return "-";
    return `**** **** **** ${data.slice(-4)}`;
  };

  return (
    <div className="v_card d-flex flex-column align-items-start justify-content-between w-100">
      <div className="d-flex align-items-start justify-content-between w-100">
        <span className="holder">{data?.nameOnCard ?? "-"}</span>
        <span className="status">
          {data?.status?.toLowerCase() ??
            data?.expiryStatus?.toLowerCase() ??
            "-"}
        </span>
      </div>

      <h2 className="number">
        {formatCardPan(data?.cardPAN ?? "") ??
          getMasked(data?.maskedPan ?? "") ??
          "-"}
      </h2>
      <div className="w-100">
        <div className="d-flex align-items-center justify-content-between w-100">
          <div className="key">Expiry Date</div>
          <div className="key">CVV</div>
        </div>
        <div className="d-flex align-items-center justify-content-between w-100">
          <div className="val">
            {data?.expiryDate || data?.expirationDate
              ? format(
                  new Date(data?.expiryDate ?? data?.expirationDate),
                  "MM/yy"
                )
              : "-"}
          </div>
          <div className="val">{data?.cvv ?? "-"}</div>
        </div>
      </div>
    </div>
  );
};

export default VirtualCard;
