import { IFilterDropdownProps } from 'interfaces';
import React, { useState } from "react";
import { Dropdown, DropdownItem, DropdownMenu, DropdownToggle } from "reactstrap";
import "./filterDropdown.scss";

const FilterDropdown: React.FC<IFilterDropdownProps> = ({ items, onItemClick, onToggle, children, buttonContent, useStartIcon, remixStartIconKey, grayBtn }) => {
  const [isOpen, setIsOpen] = useState(false);
  
  return (
    <Dropdown
      toggle={() => {
        setIsOpen(!isOpen);
        onToggle && onToggle({ isOpen });
      }}
      isOpen={isOpen}
    >
      <DropdownToggle color='default' className={`d-flex align-items-center justify-content-center toggle-btn ${grayBtn ? 'gray': ""}`}>
        {useStartIcon && <i className={`ri-${remixStartIconKey || "filter-3"}-line`}></i>}&nbsp; {buttonContent || "Filter"} &nbsp;
        <i className={`ri-arrow-drop-${isOpen ? "up" : "down"}-line`}></i>
      </DropdownToggle>
      {children && (
        <DropdownMenu container="body" end>
          {children}
        </DropdownMenu>
      )}
      {items && !children && (
        <DropdownMenu container="body" end>
          {items?.map((item: any) => (
            <DropdownItem
               key={item}
              onClick={() => {
                onItemClick && onItemClick(item);
              }}
            >
              {item}
            </DropdownItem>
          ))}
        </DropdownMenu>
      )}
    </Dropdown>
  );
};

export default FilterDropdown;
