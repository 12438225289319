export const maskPanNumber = (panNumber: number) => {
  let subNum = panNumber.toString().substring(2, 4);
  return panNumber.toString().replace(subNum, "***");
};

export const getPanTime = (num: number) => {
  return `${Math.floor(num / 60)} : ${num % 60}`;
};

export function formatCardPan(cardPan: string) {
  if (!cardPan || typeof cardPan !== "string") return null;

  cardPan = cardPan.replace(/\s/g, "");
  return cardPan.replace(/(\d{4})(?=\d)/g, "$1 ");
}

export const getSuccessMsg = (key: string) => {
  if (key === "limit") return "Limit has been set successfully";
  if (key === "topup") return "Card has been topped up successfully";
  return "";
};
