import {
  Control,
  Controller,
  FieldErrors,
  FieldValues,
  UseFormRegister,
  UseFormTrigger,
  UseFormWatch,
} from "react-hook-form";
import SelectControl from "components/selectControl/SelectControl";
import Select from "react-select";
import "./createCardModal.scss";
import { defaultValues } from "./CreateCardModal";
import { FC } from "react";
import { Button } from "vgg-ui-react/dist/components/Core";

export interface StepProps {
  register: UseFormRegister<typeof defaultValues>;
  control: Control<FieldValues>;
  errors: FieldErrors<typeof defaultValues>;
  currencyOptions?: { label: string; value: string }[];
  countryOptions?: { label: string; value: string }[];
  accountTypeOptions?: { label: string; value: string }[];
  cardTypeOptions?: { label: string; value: string }[];
  onNextClick?: () => void;
  watch?: UseFormWatch<typeof defaultValues>;
  fetchingCurrencies?: boolean;
  fetchingAccountTypes?: boolean;
  fetchingCardTypes?: boolean;
  trigger?: UseFormTrigger<typeof defaultValues>;
}

const StepOne: FC<StepProps> = ({
  register,
  control,
  errors,
  currencyOptions,
  accountTypeOptions,
  cardTypeOptions,
  onNextClick,
  watch,
  fetchingCurrencies,
  fetchingAccountTypes,
  fetchingCardTypes,
}) => {
  const watchCardName = watch ? watch("cardHolderName") : "";
  const watchCurrency = watch ? watch("currency") : "";
  const watchAccountType = watch ? watch("accountType") : "";
  const watchCardType = watch ? watch("cardType") : "";

  return (
    <div className="d-flex flex-column gap-4">
      <div>
        <label htmlFor="cardHolderName" className="form-label">
          Cardholder Name
        </label>
        <input
          {...register("cardHolderName", { required: true })}
          type="text"
          className={`form-control ${
            errors?.cardHolderName?.message ? "is-invalid" : ""
          }`}
          placeholder="e.g John Doe"
        />
        <div className="invalid-feedback">
          {errors?.cardHolderName?.message}
        </div>
      </div>

      <div>
        <label htmlFor="currency" className="form-label">
          Currency
        </label>
        <Controller
          control={control}
          name="currency"
          render={({ field: { onChange, onBlur, value } }) => (
            <Select
              {...register("currency", { required: true })}
              placeholder="Select"
              onBlur={onBlur}
              isLoading={fetchingCurrencies}
              onChange={(e) => onChange({ target: e || "" })}
              //   inputRef={ref}
              // @ts-ignore
              controlClassName={`form-control ${
                errors?.currency?.message ? "is-invalid" : ""
              } ps-0 pt-0 pb-0`}
              controlErrorMsg={errors?.currency?.message}
              components={{
                IndicatorSeparator: () => null,
                Control: SelectControl,
              }}
              defaultValue={
                currencyOptions?.find((v) => v.value === value) ?? ""
              }
              options={currencyOptions}
            />
          )}
        />
      </div>

      <div>
        <label htmlFor="accountType" className="form-label">
          Account Type
        </label>
        <Controller
          control={control}
          name="accountType"
          render={({ field: { onChange, onBlur, value } }) => (
            <Select
              {...register("accountType", { required: true })}
              placeholder="Select"
              onBlur={onBlur}
              isLoading={fetchingAccountTypes}
              onChange={(e) => onChange({ target: e || "" })}
              //   inputRef={ref}
              // @ts-ignore
              controlClassName={`form-control ${
                errors?.accountType?.message ? "is-invalid" : ""
              } ps-0 pt-0 pb-0`}
              controlErrorMsg={errors?.accountType?.message}
              components={{
                IndicatorSeparator: () => null,
                Control: SelectControl,
              }}
              defaultValue={
                accountTypeOptions?.find((v) => v.value === value) ?? ""
              }
              options={accountTypeOptions}
            />
          )}
        />
      </div>

      <div>
        <label htmlFor="cardType" className="form-label">
          Card Type
        </label>
        <Controller
          control={control}
          name="cardType"
          render={({ field: { onChange, onBlur, value } }) => (
            <Select
              {...register("cardType", { required: true })}
              placeholder="Select"
              onBlur={onBlur}
              isLoading={fetchingCardTypes}
              onChange={(e) => onChange({ target: e || "" })}
              //   inputRef={ref}
              // @ts-ignore
              controlClassName={`form-control ${
                errors?.cardType?.message ? "is-invalid" : ""
              } ps-0 pt-0 pb-0`}
              controlErrorMsg={errors?.cardType?.message}
              components={{
                IndicatorSeparator: () => null,
                Control: SelectControl,
              }}
              defaultValue={
                cardTypeOptions?.find((v) => v.value === value) ?? ""
              }
              options={cardTypeOptions}
            />
          )}
        />
      </div>
      <div>
        <Button
          label="Next"
          background="blue"
          className="w-100 mt-4"
          onClick={onNextClick}
          disabled={
            !watchAccountType ||
            !watchCardName ||
            !watchCardType ||
            !watchCurrency ||
            errors?.cardHolderName?.message
          }
        />
      </div>
    </div>
  );
};

export default StepOne;
