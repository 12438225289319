import React from "react";
import { IBackButtonProp } from 'interfaces';
import "./backButton.scss";

const BackButton: React.FC<IBackButtonProp> = ({ onClick, label, noPadding, remixIconClass }) => {
  return (
    <section className={`head-section d-flex sp-mb--24 ${noPadding ? "px-0" : ""}`}>
      <div className="head-section__icon d-flex justify-content-center align-items-center" onClick={e => (onClick ? onClick(e) : window.location.replace("/products"))}>
        <i className={`ri-${remixIconClass || "arrow-drop-left-line"}`}></i> {label || "Back"}
      </div>
    </section>
  );
};

export default BackButton;
