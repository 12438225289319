import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import CustomSpinner from "components/CustomSpinner/CustomSpinner";
import { Lozenge } from 'vgg-ui-react/dist/components/Core'
import { IOnboardCurrencyList } from 'interfaces';
import "./onboardCurrencyList.scss";

const OnboardCurrencyList: React.FC<IOnboardCurrencyList> = ({ onSelect, currencies, searchValue, selectedCurrency, loading, onboardedCurrencies }) => {
  const [currencyList, setCurrencyList] = useState<any>([])
  const [payloadList, setPayloadList] = useState<any>([])
  const { currencyListPayload } = useSelector(({ cards }: any) => cards);

  const onCurrencySearch = (e:any) => {
     const value = e.target.value;
     if(value === "") {
       setPayloadList(currencyList)
     }else {
       let res:any = []
       currencyList.forEach((val:any) => {
         if(val?.name?.toLowerCase().search(value.toLowerCase()) > -1) {
           res.push(val)
         }
       })
       setPayloadList(res)
     }
  }

  useEffect(() => {
    if (currencies) {
      let approvedCurrency: any = []
      currencyListPayload?.data?.responseData?.forEach((val: any) => {
        if (val?.status === "APPROVED") {
          approvedCurrency.push(val.currency)
        }
      })
      const lists: any = currencies.map(val => {
        if (approvedCurrency.indexOf(val.currencyCode) > -1) {
          val["active"] = true
          return val
        }
        val["active"] = false
        return val
      })
      setCurrencyList(lists);
      setPayloadList(lists)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currencies])


  return (
    <div className="col-12 onboard-currency-wrapper">
      <div className="wrap-search">
        <input
          disabled={loading}
          className="form-control"
          placeholder="Search currencies"
          onChange={onCurrencySearch}
        />
      </div>
      <div className="currencies-main">
        {loading ? (
          <CustomSpinner />
        ) : (
          <>
            <p className="currencies-main__instruction">Choose any country to continue.</p>
            <div className="currencies-main__list">
              {payloadList && payloadList.length > 0 ? (
                <>
                  {payloadList?.map((currency: any) => (
                    <div
                      key={currency?.id}
                      title={!currency.active ? `No provider available for ${currency.name} yet` : ""}
                      className={`d-flex align-items-center justify-content-between currency_item ${currency.code === selectedCurrency ? "active" : ""} ${currency.active || !currency.isCardSupported || onboardedCurrencies?.includes(currency.currencyCode) ? "disabled" : "enabled"
                        }`}
                      onClick={() => !currency.active && currency.isCardSupported && !(onboardedCurrencies?.includes(currency.currencyCode)) && onSelect(currency.currencyCode)}
                    >
                      <div className='d-flex align-items-center flex-1'><span className='flex-1'>{currency.name}</span> <div className='flex-1'><Lozenge content={!currency.isCardSupported ? "Unsupported" : onboardedCurrencies?.includes(currency.currencyCode) ? "Onboarded" : "Available"} variant={!currency.isCardSupported ? "failed" : onboardedCurrencies?.includes(currency.currencyCode) ? "success" : "pending"} /></div></div>
                      <i className="ri-arrow-right-s-line"></i>
                    </div>
                  ))}
                </>
              ) : (
                <div className="d-flex align-items-center justify-content-center flex-wrap no-data">
                  {searchValue ? <span>"{`${searchValue}`}" not found</span> : <span>No Data</span>}
                </div>
              )}
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default OnboardCurrencyList;
