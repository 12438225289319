import { useEffect, useState } from "react";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { Button } from "vgg-ui-react/dist/components/Core";
import { CreatedCardType } from "interfaces";
import VirtualCard, {
  VirtualCardData,
} from "components/VirtualCard/VirtualCard";
import "./createdCardDetail.scss";
import { Alert } from "reactstrap";

const CreatedCardDetail = ({
  onCopy = () => {},
  data,
}: {
  onCopy?: () => void;
  data?: CreatedCardType & VirtualCardData;
}) => {
  const [copy, setCopy] = useState({
    value: data?.cardPAN ?? "",
    copied: false,
  });
  useEffect(() => {
    const unsub = setTimeout(() => {
      if (copy.copied) {
        setCopy((prev) => ({ ...prev, copied: false }));
      }
    }, 2000);
    return () => {
      if (unsub) clearTimeout(unsub);
    };
  }, [copy.copied]);
  return (
    <div className="d-flex flex-column gap-4 view-card-details">
      <VirtualCard data={data} />
      <div className="mt-120">
        <div className="instr">
          This card can only be copied and viewed once{" "}
        </div>
        <CopyToClipboard
          text={copy.value}
          onCopy={() =>
            setCopy((prev) => ({
              ...prev,
              value: data?.cardPAN ?? "",
              copied: true,
            }))
          }
        >
          <Button
            label="Copy Details"
            background="blue"
            className="w-100 mt-4"
            onClick={onCopy}
          />
        </CopyToClipboard>
        {copy.copied ? <Alert className="text-center">Copied.</Alert> : null}
      </div>
    </div>
  );
};

export default CreatedCardDetail;
