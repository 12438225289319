import { isAfter, isBefore, subYears } from "date-fns";

export const validateDateOfBirth = (value: Date | null) => {
  if (!value) return "Date of Birth is required!";
  if (isAfter(new Date(value), new Date()))
    return "Date of birth cannot be in the future";

  const eighteenYearsAgo = subYears(new Date(), 18);
  if (isBefore(eighteenYearsAgo, new Date(value)))
    return "Must be at least 18 years old";

  return "";
};
