import React from "react";
import { Route, Switch } from "react-router-dom";
import Notification from "shared/notifications/notifications";
import LandingPage from "pages/landingPage/LandingPage";
import { URLS } from "./helpers/urls";
import "./App.scss";

const App: React.FC<{ url?: string }> = ({ url }) => {
  return (
    <div className="container-fluid card-app-container">
      <Notification />
      <Switch>
        <Route path={URLS.HOME} component={LandingPage} />
      </Switch>
    </div>
  );
};

export default App;
