import React, { useState, useEffect } from "react";
import { Button } from "vgg-ui-react/dist/components/Core";
import OnboardCurrencyModal from "components/OnboardCurrencyModal/OnboardCurrencyModal";
import BackButton from "components/backButton/BackButton";
import { ReactComponent as InfoIcon } from "../../assets/images/infoLarge.svg";
import { useDispatch, useSelector } from "react-redux";
import { getOnboardedCurrencies } from "redux/actions/cards.action";
import "./awaitingApprovalPage.scss";
import { successMessage } from "redux/actions/notification";

const AwaitingApprovalPage = ({
  description = "Your onboarding request has been successfully submitted and feedback will be provided shortly.",
}: {
  description?: string;
}) => {
  const dispatch = useDispatch();
  const [open, setOpen] = useState(false);

  const { onboardedCurrencies } = useSelector(({ cards }: any) => cards);

  useEffect(() => {
    dispatch(getOnboardedCurrencies());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onOnboardClick = () => {
    setOpen(true);
  };

  return (
    <div className="awaiting-onboard-wrapper">
      <BackButton label="Cards" />
      <div className="d-flex flex-column align-items-center justify-content-center content-wrapper">
        <div className="mb-3">
          <InfoIcon />
        </div>
        <div className="mb-3">
          <h3 className="success-title">Onboard Pending!</h3>
        </div>
        <p className="mb-3 success-content">{description}</p>
        {
          <p className="mb-3 success-content">
            {onboardedCurrencies?.responseData.map(
              (v: { currency: string }) => (
                <strong className="mr-1" key={v.currency}>
                  {v.currency}
                </strong>
              )
            )}
            : Awaiting Approval
          </p>
        }

        <Button
          label="Onboard New Currency"
          background="blue"
          onClick={onOnboardClick}
        />
      </div>
      {open && (
        <OnboardCurrencyModal
          open={open}
          toggle={() => setOpen(false)}
          onClosed={() => setOpen(false)}
          onSuccess={() => {
            dispatch(getOnboardedCurrencies());
            setOpen(false);
            dispatch(successMessage("Onboard successful"));
          }}
          onboardedCurrencies={
            onboardedCurrencies?.responseData.map(
              (v: { currency: string }) => v.currency
            ) || []
          }
        />
      )}
    </div>
  );
};

export default AwaitingApprovalPage;
