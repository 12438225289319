import React from "react";
import noData from "../../assets/images/noData.svg";
import { Typography } from "vgg-ui-react/dist/components/Core";
import { Button } from "reactstrap";
import { IEmptyState } from "interfaces";
import "./empty.scss";

const EmptyState: React.FC<IEmptyState> = ({
  title,
  description,
  hideMessage = true,
  handler,
  btnName,
  showIcon,
  remixClass,
}) => {
  return (
    <div className="empty-container">
      <div>
        {showIcon ? (
          <div className="sp-mb--24">
            {remixClass ? (
              <div className="square-4">
                <i className={remixClass}></i>
              </div>
            ) : (
              <img src={noData} alt="empty" />
            )}
          </div>
        ) : null}
        <div className="text-center sp-mb--16">
          <div className="sp-mb--8">
            <Typography
              typographyVariant="subtitle1"
              text={title || "Nothing here"}
              className="font-weight-bold"
            />
          </div>
          {hideMessage ? (
            <div>
              <Typography
                typographyVariant="caption"
                text={description || "Add a user to configure options"}
                className="sp-text-color--neutral-400"
              />
            </div>
          ) : null}
          {btnName ? (
            <Button outline className="btn  btn-gray mt-4">
              <span className=" fw-bold" onClick={handler}>
                {btnName}
              </span>
            </Button>
          ) : null}
        </div>
      </div>
    </div>
  );
};

export default EmptyState;
