import React from "react";
import { PageLoader } from "components/loader/Loader";
import { format } from "date-fns";
import getSymbolFromCurrency from "currency-symbol-map";
import { Line } from "react-chartjs-2";
import { Card } from "reactstrap";
import { Typography } from "vgg-ui-react/dist/components/Core";
import transactionHistoryChartOptions from "helpers/transactionHistoryChartOptions";
import "./lineChartOverview.scss";
import EmptyState from "shared/empty/empty";

export const options = {
  responsive: true,
  plugins: {
    tooltip: {
      callbacks: {
        label: function (context: any) {
          let label = context.dataset.label || "";

          if (label) {
            label += ": ";
          }
          if (context.parsed.y !== null) {
            label += new Intl.NumberFormat("en-ng", {
              style: "currency",
              currency: "NGN",
              currencyDisplay: "symbol",
            }).format(context.parsed.y);
          }
          return label;
        },
      },
    },
    legend: {
      display: false,
      align: "start",
    },
    title: {
      display: false,
      text: "",
      align: "start",
    },
  },
};

export interface ILineChartOverviewProps {
  loading: boolean;
  dataSource: any;
  currency: string;
  yKey: string;
  xKey: string;
}

const LineChartOverview: React.FC<ILineChartOverviewProps> = ({
  loading,
  dataSource,
  currency,
  yKey,
  xKey,
}) => {
  const [data, setData] = React.useState<any>(null);

  React.useEffect(() => {
    if (dataSource && dataSource.length) {
      const d = dataSource.map((v: any) =>
        Number(v[yKey].toString().replaceAll(",", "").replaceAll(" ", ""))
      );
      setData({
        labels: dataSource?.map(
          (v: any) => v?.[xKey] && format(new Date(v[xKey]), "dd MMM")
        ),
        datasets: [
          {
            label: "Deposit",
            data: d,
            borderColor: "#175FFF",
            borderWidth: 2,
            backgroundColor: "rgba(255, 99, 132, 0.5)",
            tension: 0.3,
          },
        ],
      });
    } else {
      setData(null);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataSource]);

  return (
    <Card className="line-chart-container">
      <div className="sp-p--24 sp-mb--16 w-100 d-flex align-items-center justify-content-between bb">
        <Typography
          typographyVariant="subtitle2"
          text="Transaction Volume"
          className="font-weight-500"
        />
        <div></div>
      </div>
      {/* <div className="sp-p--24 sp-mb--16"> */}
      {/* <Typography typographyVariant="disp-heading" text={getCurrencyValue("", totalReceived)} className="font-weight-500" /> */}
      {/* </div> */}
      {loading ? (
        <div className="d-flex align-items-center justify-content-center h-100">
          <PageLoader height={35} />
        </div>
      ) : (
        <>
          {data ? (
            <Line
              options={
                transactionHistoryChartOptions(
                  getSymbolFromCurrency(currency)
                ) as any
              }
              data={data}
            />
          ) : (
            <EmptyState description="No report to display" />
          )}
        </>
      )}
    </Card>
  );
};

export default LineChartOverview;
