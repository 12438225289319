import React, { useEffect, useState } from "react";
import { Table } from "reactstrap";
import swal from "@sweetalert/with-react";
import TablePagination from "shared/tablePagination/tablePagination";
import TransactionsModal from "components/TransactionModal/TransactionsModal";
import { useDispatch, useSelector } from "react-redux";
import { getCardTransactions } from "redux/actions/cards.action";
import { errorMessage } from "redux/actions/notification";
import _debounce from "lodash.debounce";
import { ICurrencyFilter } from "interfaces";
import FilterDropdown from "shared/filterDropdown/FilterDropdown";
import { DateRangePicker } from "react-date-range";
import { differenceInDays, format, subDays } from "date-fns";
import "./Transactions.scss";
import EmptyState from "shared/empty/empty";
import { PageLoader } from "components/loader/Loader";
import { maskPanNumber } from "helpers/utils";
import { maskNumber } from "helpers/maskNumber";

const Transactions: React.FC<{
  currencyList: ICurrencyFilter[];
  fetchingCurrencyList: boolean;
}> = ({ currencyList, fetchingCurrencyList }) => {
  const dispatch = useDispatch();
  const tableHeads: string[] = [
    "Card Name",
    "Transaction Amount",
    "Type",
    "Masked Pan",
    "Currency",
    "Status",
    "Date/Time",
    "",
  ];
  const [openTransactionModal, setOpenTransactionModal] = useState(false);
  const [transactionData, setTransactionData] = useState({});
  const [currentPage, setCurrentPage] = useState(1);
  const [keyword, setKeyword] = useState("");
  const [pageSize] = useState(10);
  const [dateRange, setDateRange] = React.useState<any>({
    startDate: subDays(new Date(), 60),
    endDate: new Date(),
    key: "selection",
  });
  const [remount, setRemount] = React.useState(false);

  const onOpenViewModal = (data: any) => {
    setTransactionData(data);
    setOpenTransactionModal(true);
  };
  const searchUsers = _debounce(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const { value } = event.target;
      setKeyword(value);
    },
    1000
  );

  const { fetchingCardsByMerchant, cardsByMerchantPayload } = useSelector(
    ({ cards }: any) => {
      return {
        fetchingCardsByMerchant: cards?.fetchingCardsByMerchant,
        cardsByMerchantPayload: cards?.cardsByMerchantPayload,
      };
    }
  );
  const [selectedCurrency, setSelectedCurrency] = useState("");

  const onSelect = (value: any) => {
    const { selection } = value;
    const dateCount = differenceInDays(selection.endDate, selection.startDate);
    if (dateCount > 60) {
      swal({
        text: "Kindly select range to be at most 60 days.",
        icon: "info",
        button: "Okay",
      });
    } else {
      setDateRange(selection);
    }
  };

  useEffect(() => {
    if (
      !fetchingCardsByMerchant &&
      cardsByMerchantPayload &&
      !cardsByMerchantPayload.requestSuccessful
    ) {
      dispatch(errorMessage(cardsByMerchantPayload.message));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fetchingCardsByMerchant]);

  // when changing current page or selected currency refetch cards
  useEffect(() => {
    dispatch(
      getCardTransactions({
        currency: selectedCurrency,
        keyword,
        pageNumber: currentPage,
        pageSize,
        startDate: dateRange ? format(dateRange.startDate, "yyyy-MM-dd") : "",
        endDate: dateRange ? format(dateRange.endDate, "yyyy-MM-dd") : "",
      })
    );

    if (remount) {
      setRemount(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPage, selectedCurrency, keyword, dateRange]);

  useEffect(() => {
    if (!fetchingCurrencyList && currencyList && !selectedCurrency) {
      const curr = currencyList.filter(
        (v: ICurrencyFilter) => v.status.toLowerCase() === "approved"
      );
      if (curr.length > 0) {
        setSelectedCurrency(curr[0]?.currency);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fetchingCurrencyList, currencyList, selectedCurrency]);

  const renderContent = (range: any) => {
    return (
      <>
        {!range
          ? "Filter"
          : `${format(range.startDate, "Mo MMM, yyyy")} - ${format(
              range.endDate,
              "Mo MMM, yyyy"
            )}`}
      </>
    );
  };

  return (
    <div className="transactions-container py-0 border rounded">
      <div className="d-flex w-100 flex-row align-items-center justify-content-between header">
        <div className="position-relative search-container">
          <input
            type="search"
            placeholder="search"
            className="form-control search-input"
            onChange={searchUsers}
          />
          <i className="ri-search-line search-icon"> </i>
        </div>
        <div className="d-flex flex-row gap-2">
          <FilterDropdown buttonContent={renderContent(dateRange)}>
            <>
              {!remount && (
                <DateRangePicker
                  ranges={[dateRange]}
                  onChange={onSelect}
                  className="w-100"
                  rangeColors={["#175fff"]}
                  showDateDisplay={false}
                />
              )}
            </>
          </FilterDropdown>
          {currencyList && (
            <FilterDropdown
              items={
                currencyList
                  .filter(
                    (v: ICurrencyFilter) =>
                      v.status.toLowerCase() === "approved"
                  )
                  ?.map((v: any) => v.currency) || []
              }
              buttonContent={selectedCurrency}
              onItemClick={(curr) => setSelectedCurrency(curr)}
            />
          )}
        </div>
      </div>
      <div className="border-bottom px-3">
        {fetchingCardsByMerchant ? (
          <div className="d-flex align-items-center justify-content-center h-100">
            <PageLoader height={35} />
          </div>
        ) : (
          <>
            {cardsByMerchantPayload?.responseData?.items?.length === 0 ? (
              <EmptyState description="No data to display" />
            ) : (
              <Table striped borderless responsive>
                <thead>
                  {tableHeads.map((head, index) => (
                    <th key={index}>{head}</th>
                  ))}
                </thead>
                <tbody>
                  {cardsByMerchantPayload?.responseData?.items?.map(
                    (transaction: any, index: number) => (
                      <tr key={index}>
                        <td>{transaction.cardName}</td>
                        <td>{transaction.amount}</td>
                        <td>{transaction.transactionType || "-"}</td>
                        <td>{maskNumber(transaction.maskedPan) || "-"}</td>
                        <td>{transaction.currency}</td>
                        <td>
                          <div
                            className={`transactionStatus ${
                              transaction?.transactionStatus?.toLowerCase() ===
                              "success"
                                ? "success"
                                : "failed"
                            }`}
                          >
                            {transaction?.transactionStatus?.toUpperCase()}
                          </div>
                        </td>
                        <td>
                          {transaction.createdAt
                            ? format(
                                new Date(transaction.createdAt),
                                "yyyy/MM/dd hh:mm:ss aaa"
                              )
                            : "-"}
                        </td>
                        <td>
                          <button
                            type="button"
                            className="btn text-primary view-button"
                            onClick={() => onOpenViewModal(transaction)}
                          >
                            View
                          </button>
                        </td>
                      </tr>
                    )
                  )}
                </tbody>
              </Table>
            )}
          </>
        )}
      </div>
      <TransactionsModal
        open={openTransactionModal}
        transactionData={transactionData}
        toggle={() => setOpenTransactionModal(!setOpenTransactionModal)}
      />
      {cardsByMerchantPayload &&
      cardsByMerchantPayload?.responseData?.items?.length > 0 ? (
        <div className="px-3">
          <TablePagination
            totalCount={cardsByMerchantPayload?.responseData?.count}
            currentPage={cardsByMerchantPayload?.responseData.pageNumber}
            pageSize={cardsByMerchantPayload?.responseData.pageSize}
            onPageChange={(page: number) => setCurrentPage(page)}
            totalPages={cardsByMerchantPayload?.responseData.pages.toString()}
          />
        </div>
      ) : null}
    </div>
  );
};

export default Transactions;
