import {
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
} from "reactstrap";
import { ReactComponent as HorizontalDots } from "../../assets/images/horizontalDots.svg";
import { ReactNode, useState } from "react";

import "./actionDropdown.scss";

export type DropdownDataType = {
  label: ReactNode;
  value: string;
  disabled?: boolean;
};
const ActionDropdown = ({
  data,
  onItemClick = () => {},
}: {
  data: DropdownDataType[];
  onItemClick?: (payload: DropdownDataType) => void;
}) => {
  const [dropdownOpen, setDropdownOpen] = useState(false);

  const toggle = () => setDropdownOpen((prevState) => !prevState);
  return (
    // <div>
    //   <button className="btn">
    //     <HorizontalDots />
    //   </button>
    // </div>
    <Dropdown isOpen={dropdownOpen} toggle={toggle}>
      <DropdownToggle className="bg-transparent border-0 p-0">
        <HorizontalDots />
      </DropdownToggle>
      <DropdownMenu className="dd_menu">
        {data.map((v: DropdownDataType) => (
          <DropdownItem
            className="rounded-0 dd_menu-item"
            disabled={v.disabled}
            onClick={() => onItemClick(v)}
            key={v.value}
          >
            {v.label}
          </DropdownItem>
        ))}
      </DropdownMenu>
    </Dropdown>
  );
};

export default ActionDropdown;
