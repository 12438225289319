import React from "react";
import { useSelector } from "react-redux";
import "./statsBox.scss";

const StatsBox: React.FC<{
  title: string;
  icon: any;
  stat: string;
  color: string;
}> = ({ title, icon, stat, color }) => {
  const { fetchingCards } = useSelector(({ cards }: any) => {
    return {
      fetchingCards: cards?.fetchingCards,
    };
  });

  return (
    <div className="card_div">
      <div>
        <div className="text-secondary mb-1">{title}</div>
        <div className="fw-bold">{fetchingCards ? "loading..." : stat}</div>
      </div>
      <div
        className="icon-contain d-flex align-items-center justify-content-center"
        style={{ backgroundColor: `${color}` }}
      >
        {icon}
      </div>
    </div>
  );
};

export default StatsBox;
