const transactionHistoryChartOptions = (currency?: string) => ({
  responsive: true,
  plugins: {
    legend: null,
    title: null,
    tooltip: {
      x: 300,
      y: 400,
      width: 300,
      height: 400,
      xAlign: "center",
      yAlign: "bottom",
      displayColors: false,
      backgroundColor: "#0A2540",
      bodyColor: "#fff",
      cornerRadius: 0,
      titleFont: { weight: 400, size: 12 },
      bodyFont: { weight: 600, size: 16 },
      footerFont: { weight: 400, size: 12 },
      footerSpacing: 10,
      padding: { top: 12, left: 48, right: 48, bottom: 12 },
      titleAlign: "center",
      bodyAlign: "center",
      footerAlign: "center",
      callbacks: {
        title: function (value: any) {
          const label = value[0]?.dataset?.label;
          return `${label ? label : ""} Balance`.trim();
        },
        label: function (context: any) {
          let label = "";

          if (label) {
            label += ": ";
          }
          if (context.parsed.y !== null) {
            label += `${currency}${context.parsed.y.toFixed(2)}`;
            // label += new Intl.NumberFormat("en-ng", {
            //   style: "currency",
            //   currency,
            //   currencyDisplay: "symbol",
            // }).format(context.parsed.y);
          }
          return label;
        },
        footer: function (context: any) {
          return context[0].label;
        },
      },
    },
  },
});

export default transactionHistoryChartOptions;