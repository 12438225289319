import React, { ReactNode, useState } from "react";
import "./accordion.scss";

type AccordionData = {
  id: string;
  title: string | ReactNode;
  content: string | ReactNode;
};
type AccordionItemProps = {
  data: AccordionData;
};

type AccordionProps = {
  data: AccordionData[];
  gap?: 0 | 1 | 1.5 | 2 | 3 | 4;
};

const AccordionItem: React.FC<AccordionItemProps> = ({ data }) => {
  const [isActive, setIsActive] = useState(false);
  return (
    <div className="custom-accordion-item">
      <div
        className="custom-accordion-item__title"
        onClick={() => setIsActive(!isActive)}
      >
        <div>{data.title}</div>
        <div>
          <i className={`ri-arrow-${isActive ? "up" : "down"}-s-line`}></i>
        </div>
      </div>
      {isActive && (
        <div className="custom-accordion-item__content fade-in">
          {data.content}
        </div>
      )}
    </div>
  );
};

function Accordion({ data, gap = 0 }: AccordionProps) {
  return (
    <div className={`custom-accordion gap-${gap.toString().replace(".", "")}`}>
      {data.map((item: AccordionData) => (
        <AccordionItem key={item.id} data={item} />
      ))}
    </div>
  );
}

export default Accordion;
