import { Button } from "reactstrap";
import "./errorPage.scss";

const ErrorPage = ({ message }: {message?:string}) => {
  return (
    <div id="center pageMessages">
      <div className="center alert animated flipInX alert-danger alert-dismissible">
        <h4>
          <i className="fa ffa fa-exclamation-circle"></i>Opps!
        </h4>
        <strong>Something went wrong</strong>
        <p>{message || "Sorry we could not detect your status on this service."}</p>
        <span className="close" data-dismiss="alert">
          <i className="fa fa-times-circle"></i>
        </span>
        <Button className="btn btn-danger" onClick={() => window.location.replace("/dashboard")}>
          Back to Dashboard
        </Button>
        <Button className="btn btn-danger ms-3" onClick={() => window.location.replace("/products")}>
          Back to Products
        </Button>
      </div>
    </div>
  );
};

export default ErrorPage;
