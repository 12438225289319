import { ActionTypes } from "redux/actions/cards.action";
import { FAILURE, REQUEST, SUCCESS } from "./action-type.util";

const INITIAL_STATE = {};

const cardsReducer = (state = INITIAL_STATE, actions: any) => {
  switch (actions.type) {
    case REQUEST(ActionTypes.TOPUP_CARD):
      return {
        ...state,
        topingCard: true,
        currenciesPayload: null,
        topupCardError: null,
      };
    case SUCCESS(ActionTypes.TOPUP_CARD):
      return {
        ...state,
        topupCardPayload: actions.payload,
        topupCardError: null,
        topingCard: false,
      };
    case FAILURE(ActionTypes.TOPUP_CARD):
      return {
        ...state,
        topupCardError: actions.payload,
        topupCardPayload: null,
        topingCard: false,
      };
    case REQUEST(ActionTypes.SUPPORTED_CURRENCIES):
      return {
        ...state,
        fetchingCurrencies: true,
        topupCardPayload: null,
        currenciesError: null,
      };
    case SUCCESS(ActionTypes.SUPPORTED_CURRENCIES):
      return {
        ...state,
        currenciesPayload: actions.payload,
        currenciesError: null,
        fetchingCurrencies: false,
      };
    case FAILURE(ActionTypes.SUPPORTED_CURRENCIES):
      return {
        ...state,
        currenciesError: actions.payload,
        currenciesPayload: null,
        fetchingCurrencies: false,
      };
    case REQUEST(ActionTypes.GET_SERVICE_MANAGER_COUNTRIES):
      return {
        ...state,
        fetchingCountries: true,
        countriesPayload: null,
        countriesError: null,
      };
    case SUCCESS(ActionTypes.GET_SERVICE_MANAGER_COUNTRIES):
      return {
        ...state,
        countriesPayload: actions.payload,
        countriesError: null,
        fetchingCountries: false,
      };
    case FAILURE(ActionTypes.GET_SERVICE_MANAGER_COUNTRIES):
      return {
        ...state,
        countriesError: actions.payload,
        countriesPayload: null,
        fetchingCountries: false,
      };
    case REQUEST(ActionTypes.CURRENCY_LIST):
      return {
        ...state,
        fetchingCurrencyList: true,
        currencyListPayload: null,
        currencyListError: null,
      };
    case SUCCESS(ActionTypes.CURRENCY_LIST):
      return {
        ...state,
        currencyListPayload: actions.payload,
        currencyListError: null,
        fetchingCurrencyList: false,
      };
    case FAILURE(ActionTypes.CURRENCY_LIST):
      return {
        ...state,
        currencyListError: actions.payload,
        currencyListPayload: null,
        fetchingCurrencyList: false,
      };
    case REQUEST(ActionTypes.GET_ACCOUNT_TYPES):
      return {
        ...state,
        fetchingAccountTypes: true,
        accountTypesPayload: null,
        accountTypesError: null,
      };
    case SUCCESS(ActionTypes.GET_ACCOUNT_TYPES):
      return {
        ...state,
        accountTypesPayload: actions.payload,
        accountTypesError: null,
        fetchingAccountTypes: false,
      };
    case FAILURE(ActionTypes.GET_ACCOUNT_TYPES):
      return {
        ...state,
        accountTypesError: actions.payload,
        accountTypesPayload: null,
        fetchingAccountTypes: false,
      };
    case REQUEST(ActionTypes.GET_CARD_TYPES):
      return {
        ...state,
        fetchingCardTypes: true,
        cardTypesPayload: null,
        cardTypesError: null,
      };
    case SUCCESS(ActionTypes.GET_CARD_TYPES):
      return {
        ...state,
        cardTypesPayload: actions.payload,
        cardTypesError: null,
        fetchingCardTypes: false,
      };
    case FAILURE(ActionTypes.GET_CARD_TYPES):
      return {
        ...state,
        cardTypesError: actions.payload,
        cardTypesPayload: null,
        fetchingCardTypes: false,
      };
    case REQUEST(ActionTypes.CREATE_CARD):
      return {
        ...state,
        creatingCard: true,
        createCardPayload: null,
        createCardError: null,
      };
    case SUCCESS(ActionTypes.CREATE_CARD):
      return {
        ...state,
        createCardPayload: actions.payload,
        createCardError: null,
        creatingCard: false,
      };
    case FAILURE(ActionTypes.CREATE_CARD):
      return {
        ...state,
        createCardError: actions.payload,
        createCardPayload: null,
        creatingCard: false,
      };
    case REQUEST(ActionTypes.ONBOARD_CURRENCY):
      return {
        ...state,
        onboardingCurrency: true,
        onboardCurrencyPayload: null,
        onboardCurrencyError: null,
      };
    case SUCCESS(ActionTypes.ONBOARD_CURRENCY):
      return {
        ...state,
        onboardCurrencyPayload: actions.payload,
        onboardCurrencyError: null,
        onboardingCurrency: false,
      };
    case FAILURE(ActionTypes.ONBOARD_CURRENCY):
      return {
        ...state,
        onboardCurrencyError: actions.payload,
        onboardCurrencyPayload: null,
        onboardingCurrency: false,
      };
    case REQUEST(ActionTypes.EARNINGS):
      return {
        ...state,
        fetchingEarnings: true,
        earningsPayload: null,
        earningsError: null,
      };
    case SUCCESS(ActionTypes.EARNINGS):
      return {
        ...state,
        earningsPayload: actions.payload,
        earningsError: null,
        fetchingEarnings: false,
      };
    case FAILURE(ActionTypes.EARNINGS):
      return {
        ...state,
        earningsError: actions.payload,
        earningsPayload: null,
        fetchingEarnings: false,
      };
    case REQUEST(ActionTypes.SETTLEMENTS):
      return {
        ...state,
        fetchingSettlements: true,
        settlementsPayload: null,
        earningsError: null,
      };
    case SUCCESS(ActionTypes.SETTLEMENTS):
      return {
        ...state,
        settlementsPayload: actions.payload,
        settlementsError: null,
        fetchingSettlements: false,
      };
    case FAILURE(ActionTypes.SETTLEMENTS):
      return {
        ...state,
        settlementsError: actions.payload,
        settlementsPayload: null,
        fetchingSettlements: false,
      };

    case REQUEST(ActionTypes.CHECK_MERCHANT_STATUS):
      return {
        ...state,
        checkingMerchantStatus: true,
        merchantStatus: null,
        merchantStatusError: null,
      };
    case SUCCESS(ActionTypes.CHECK_MERCHANT_STATUS):
      return {
        ...state,
        checkingMerchantStatus: false,
        merchantStatus: actions.payload,
        merchantStatusError: null,
      };
    case FAILURE(ActionTypes.CHECK_MERCHANT_STATUS):
      return {
        ...state,
        merchantStatus: null,
        checkingMerchantStatus: false,
        merchantStatusError: actions.payload,
      };

    case REQUEST(ActionTypes.FETCH_REPORTS):
      return {
        ...state,
        fetchingReports: true,
        reportsPayload: null,
      };

    case SUCCESS(ActionTypes.FETCH_REPORTS):
      return {
        ...state,
        fetchingReports: false,
        reportsPayload: actions.payload,
      };

    case FAILURE(ActionTypes.FETCH_REPORTS):
      return {
        ...state,
        fetchingReports: false,
        reportsPayload: null,
      };
    case REQUEST(ActionTypes.GET_ALL_CURRENCY):
      return {
        ...state,
        fetchingCurrency: true,
        currencyPayload: null,
        currencyError: null,
      };
    case SUCCESS(ActionTypes.GET_ALL_CURRENCY):
      return {
        ...state,
        fetchingCurrency: false,
        currencyPayload: actions.payload.data,
        currencyError: null,
      };
    case FAILURE(ActionTypes.GET_ALL_CURRENCY):
      return {
        ...state,
        fetchingCurrency: false,
        currencyPayload: null,
        currencyError: actions.payload,
      };
    case REQUEST(ActionTypes.GET_ALL_CARDS):
      return {
        ...state,
        fetchingCards: true,
        cardsPayload: null,
        cardsError: null,
      };
    case SUCCESS(ActionTypes.GET_ALL_CARDS):
      return {
        ...state,
        fetchingCards: false,
        cardsPayload: actions.payload.data,
        cardsError: null,
      };
    case FAILURE(ActionTypes.GET_ALL_CARDS):
      return {
        ...state,
        fetchingCards: false,
        cardsPayload: null,
        cardsError: actions.payload,
      };
    case REQUEST(ActionTypes.GET_CARD_BY_ID):
      return {
        ...state,
        fetchingCard: true,
        cardPayload: null,
        cardError: null,
      };
    case SUCCESS(ActionTypes.GET_CARD_BY_ID):
      return {
        ...state,
        fetchingCard: false,
        cardPayload: actions.payload,
        cardError: null,
      };
    case FAILURE(ActionTypes.GET_CARD_BY_ID):
      return {
        ...state,
        fetchingCard: false,
        cardPayload: null,
        cardError: actions.payload,
      };
    case REQUEST(ActionTypes.GET_CARD_BALANCE_BY_ID):
      return {
        ...state,
        fetchingCardBalance: true,
        cardBalancePayload: null,
        cardBalanceError: null,
      };
    case SUCCESS(ActionTypes.GET_CARD_BALANCE_BY_ID):
      return {
        ...state,
        fetchingCardBalance: false,
        cardBalancePayload: actions.payload,
        cardBalanceError: null,
      };
    case FAILURE(ActionTypes.GET_CARD_BALANCE_BY_ID):
      return {
        ...state,
        fetchingCardBalance: false,
        cardBalancePayload: null,
        cardBalanceError: actions.payload,
      };
    case REQUEST(ActionTypes.SETUP_LIMIT):
      return {
        ...state,
        settingUpLimit: true,
        setupLimitPayload: null,
        setupLimitError: null,
      };
    case SUCCESS(ActionTypes.SETUP_LIMIT):
      return {
        ...state,
        settingUpLimit: false,
        setupLimitPayload: actions.payload,
        setupLimitError: null,
      };
    case FAILURE(ActionTypes.SETUP_LIMIT):
      return {
        ...state,
        settingUpLimit: false,
        setupLimitPayload: null,
        setupLimitError: actions.payload,
      };
    case REQUEST(ActionTypes.GET_CARD_TRANSACTIONS):
      return {
        ...state,
        fetchingCardsByMerchant: true,
        cardsByMerchantPayload: null,
        cardsByMerchantError: null,
      };
    case SUCCESS(ActionTypes.GET_CARD_TRANSACTIONS):
      return {
        ...state,
        fetchingCardsByMerchant: false,
        cardsByMerchantPayload: actions.payload.data,
        cardsByMerchantError: null,
      };
    case FAILURE(ActionTypes.GET_CARD_TRANSACTIONS):
      return {
        ...state,
        fetchingCardsByMerchant: false,
        cardsByMerchantPayload: null,
        cardsByMerchantError: actions.payload,
      };
    case REQUEST(ActionTypes.GET_CARD_ONBOARDED_CURRENCIES):
      return {
        ...state,
        fetchingOnboardedCurrencies: true,
        onboardedCurrencies: null,
        onboardedCurrenciesError: null,
      };
    case SUCCESS(ActionTypes.GET_CARD_ONBOARDED_CURRENCIES):
      return {
        ...state,
        fetchingOnboardedCurrencies: false,
        onboardedCurrencies: actions.payload.data,
        onboardedCurrenciesError: null,
      };
    case FAILURE(ActionTypes.GET_CARD_ONBOARDED_CURRENCIES):
      return {
        ...state,
        fetchingOnboardedCurrencies: false,
        onboardedCurrencies: null,
        onboardedCurrenciesError: actions.payload,
      };

    case REQUEST(ActionTypes.GET_WALLETS):
      return {
        ...state,
        walletsPayload: null,
        walletsError: null,
        fetchingWallets: true,
      };

    case SUCCESS(ActionTypes.GET_WALLETS):
      return {
        ...state,
        fetchingWallets: false,
        walletsPayload: actions.payload,
        walletsError: null,
      };

    case FAILURE(ActionTypes.GET_WALLETS):
      return {
        ...state,
        fetchingWallets: false,
        walletsPayload: null,
        walletsError: actions.payload,
      };

    default:
      return { ...state };
  }
};

export default cardsReducer;
