import axios from "axios";
import { BehaviorSubject } from "rxjs";

import { cardsBaseApiUrl, config } from "./config.service";
import { signout } from "./user.service";

export const subscriber = new BehaviorSubject(0);

axios.interceptors.request.use(
  function (config) {
    return config;
  },
  function (error) {
    if (error?.response?.status === 401) {
      signout();
    }
    return Promise.reject(error);
  }
);

// Add a response interceptor
axios.interceptors.response.use(
  function (response) {
    return response;
  },
  function (error) {
    if (error.response?.status === 401) {
      signout();
    }
    return Promise.reject(error);
  }
);

export const Get = async (endpoint: string, customBaseUrl?: string) => {
  try {
    return await axios.get(
      `${customBaseUrl ? customBaseUrl : cardsBaseApiUrl}/${endpoint}`,
      config()
    );
  } catch (error: any) {
    if (error.response && error.response.status === 403) {
      throw error;
    }
    return error.response;
  }
};

export const Post = async (
  endpoint: string,
  data: any,
  customBaseUrl?: string
) => {
  try {
    return await axios.post(
      `${customBaseUrl ? customBaseUrl : cardsBaseApiUrl}/${endpoint}`,
      data,
      config()
    );
  } catch (error: any) {
    return error.response;
  }
};

export const Patch = async (
  endpoint: string,
  data: any,
  customBaseUrl?: string
) => {
  try {
    return await axios.patch(
      `${customBaseUrl ? customBaseUrl : cardsBaseApiUrl}/${endpoint}`,
      data,
      config()
    );
  } catch (error: any) {
    return error.response;
  }
};

export const Put = async (
  endpoint: string,
  data: any,
  customBaseUrl?: string
) => {
  try {
    return await axios.put(
      `${customBaseUrl ? customBaseUrl : cardsBaseApiUrl}/${endpoint}`,
      data,
      config()
    );
  } catch (error: any) {
    return error.response;
  }
};

export const Delete = async (
  endpoint: string,
  data: any,
  customBaseUrl?: string
) => {
  try {
    return await axios.delete(
      `${customBaseUrl ? customBaseUrl : cardsBaseApiUrl}/${endpoint}`,
      config()
    );
  } catch (error: any) {
    return error.response;
  }
};
