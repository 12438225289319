import React from "react";
import { StepProps } from "./StepOne";
import { Controller } from "react-hook-form";
import Select from "react-select";
import SelectControl from "components/selectControl/SelectControl";
import { Button } from "vgg-ui-react/dist/components/Core";
import ReactDatePicker from "react-datepicker";
import BaseOneCalendarContainer from "components/CalendarContainer";
import { validateDateOfBirth } from "helpers/validateDateOfBirth";

interface StepTwoProps extends StepProps {
  onGoBack: () => void;
  fetchingCountries?: boolean;
  isValid: boolean;
  walletOptions: { label: string; value: string }[];
  fetchingWallets?: boolean;
}

const StepTwo: React.FC<StepTwoProps> = ({
  register,
  control,
  errors,
  countryOptions,
  walletOptions,
  fetchingCountries,
  fetchingWallets,
  watch,
  onGoBack,
  isValid,
}) => {
  const [dobMessage, setDobMessage] = React.useState("");
  const selectedDate =
    watch && watch("dateOfBirth") ? new Date(watch("dateOfBirth")) : null;

  return (
    <div className="d-flex flex-column gap-4">
      <div>
        <label htmlFor="country" className="form-label">
          Country
        </label>
        <Controller
          control={control}
          name="country"
          render={({ field: { onChange, onBlur, value } }) => (
            <Select
              id="country"
              {...register("country", { required: true })}
              placeholder=""
              onBlur={onBlur}
              isLoading={fetchingCountries}
              onChange={(e) => onChange({ target: e || "" })}
              //   inputRef={ref}
              // @ts-ignore
              controlClassName={`form-control ${
                errors?.country?.message ? "is-invalid" : ""
              } ps-0 pt-0 pb-0`}
              controlErrorMsg={errors?.country?.message}
              components={{
                IndicatorSeparator: () => null,
                Control: SelectControl,
              }}
              defaultValue={
                countryOptions?.find((v) => v.value === value) ?? ""
              }
              options={countryOptions}
            />
          )}
        />
      </div>

      <div>
        <label htmlFor="firstName" className="form-label">
          First Name
        </label>
        <input
          id="firstName"
          {...register("firstName", { required: true })}
          type="text"
          className={`form-control ${
            errors?.firstName?.message ? "is-invalid" : ""
          }`}
          placeholder="e.g John"
        />
        <div className="invalid-feedback">{errors?.firstName?.message}</div>
      </div>

      <div>
        <label htmlFor="lastName" className="form-label">
          Last Name
        </label>
        <input
          id="lastName"
          {...register("lastName", { required: true })}
          type="text"
          className={`form-control ${
            errors?.lastName?.message ? "is-invalid" : ""
          }`}
          placeholder="e.g Doe"
        />
        <div className="invalid-feedback">{errors?.lastName?.message}</div>
      </div>

      <div>
        <label htmlFor="bvn" className="form-label">
          BVN
        </label>
        <input
          id="bvn"
          {...register("bvn", { required: true })}
          type="text"
          className={`form-control ${errors?.bvn?.message ? "is-invalid" : ""}`}
          placeholder="e.g 0123456789"
        />
        <div className="invalid-feedback">{errors?.bvn?.message}</div>
      </div>

      <div>
        <label htmlFor="phoneNumber" className="form-label">
          Phone Number
        </label>
        <input
          id="phoneNumber"
          {...register("phoneNumber", { required: true })}
          type="text"
          className={`form-control ${
            errors?.phoneNumber?.message ? "is-invalid" : ""
          }`}
          placeholder="e.g 0123456789"
        />
        <div className="invalid-feedback">{errors?.phoneNumber?.message}</div>
      </div>

      <div>
        <label htmlFor="email" className="form-label">
          Email
        </label>
        <input
          id="email"
          {...register("email", { required: true })}
          type="text"
          className={`form-control ${
            errors?.email?.message ? "is-invalid" : ""
          }`}
          placeholder="e.g name@mail.com"
        />
        <div className="invalid-feedback">{errors?.email?.message}</div>
      </div>

      <div className="d-flex flex-column">
        <label htmlFor="dateOfBirth" className="form-label">
          D.O.B
        </label>
        <Controller
          render={({ field: { onChange, onBlur, value } }) => (
            <ReactDatePicker
              selected={selectedDate}
              onChange={(date) => {
                setDobMessage(validateDateOfBirth(date));
                return onChange({
                  target: { value: date ?? "", name: "dateOfBirth" },
                });
              }}
              className={`form-control ${
                errors?.dateOfBirth?.message ? "is-invalid" : ""
              }`}
              id="dateOfBirth"
              name="dateOfBirth"
              dateFormat="dd/MM/yyyy"
              calendarContainer={BaseOneCalendarContainer}
              placeholderText="DD/MM/YYYY"
              showYearDropdown
              dropdownMode="select"
            />
          )}
          control={control}
          name="dateOfBirth"
        />
        {(dobMessage || errors?.dateOfBirth?.message) && (
          <div className="text-danger small">
            {errors?.dateOfBirth?.message || dobMessage}
          </div>
        )}
      </div>

      <div>
        <label htmlFor="debitWallet" className="form-label">
          Debit Wallet
        </label>
        <Controller
          control={control}
          name="debitWallet"
          render={({ field: { onChange, onBlur, value } }) => (
            <Select
              id="debitWallet"
              {...register("debitWallet", { required: true })}
              placeholder="Select wallet to debit for issuance fee"
              onBlur={onBlur}
              isLoading={fetchingWallets}
              onChange={(e) => onChange({ target: e || "" })}
              //   inputRef={ref}
              // @ts-ignore
              controlClassName={`form-control ${
                errors?.debitWallet?.message ? "is-invalid" : ""
              } ps-0 pt-0 pb-0`}
              controlErrorMsg={errors?.debitWallet?.message}
              components={{
                IndicatorSeparator: () => null,
                Control: SelectControl,
              }}
              defaultValue={walletOptions?.find((v) => v.value === value) ?? ""}
              options={walletOptions}
            />
          )}
        />
      </div>

      <div className="d-flex flex-column">
        <Button
          type="submit"
          label="Create Card"
          background="blue"
          className="mt-4"
          fullWidth
          disabled={!isValid || dobMessage}
        />
        <Button
          label="Go back"
          variant="outline"
          background="neutral"
          className="mt-2"
          fullWidth
          onClick={onGoBack}
        />
      </div>
    </div>
  );
};

export default StepTwo;
