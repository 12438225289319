import React, { useEffect } from "react";
import { Switch } from "react-router-dom";
import { Route } from "react-router-dom";
import { useHistory } from "react-router-dom";
import BackButton from "../../components/backButton/BackButton";
import CustomTab from "../../components/customTab/CustomTab";
import URLS from "../../helpers/urls";
import Transactions from "pages/transactions/Transactions";
import Analytics from "pages/analytics/Analytics";
import Overview from "pages/Overview/Overview";
// import Earnings from "pages/earnings/Earnings";
// import Settlements from "pages/settlements/Settlements";
import { useDispatch, useSelector } from "react-redux";
import { fetchCurrencyList } from "redux/actions/cards.action";

export interface ITabItem {
  id: number;
  title: string;
  key: string;
  routeLink: string;
  component?: React.FC<any>;
  subLinks?: ITabItem[];
}
const tabItems: ITabItem[] = [
  {
    id: 1,
    title: "Overview",
    key: "overview",
    routeLink: URLS.Overview,
    component: Overview,
    subLinks: [],
  },
  {
    id: 2,
    title: "Cards",
    key: "cards",
    routeLink: URLS.Cards,
    component: Analytics,
    subLinks: [],
  },
  {
    id: 3,
    title: "Transactions",
    key: "transactions",
    routeLink: URLS.TRANSACTIONS,
    component: Transactions,
    subLinks: [
      {
        id: 31,
        title: "Overview",
        key: "transactions",
        routeLink: URLS.TRANSACTIONS,
        component: Transactions,
      },
      // {
      //   id: 32,
      //   title: "Live Transactions",
      //   key: "grafana",
      //   routeLink: URLS.ANALYTICS,
      //   component: Analytics
      // }
    ],
  },
  // {
  //   id: 4,
  //   title: "Earnings",
  //   key: "earningsParent",
  //   routeLink: URLS.Earnings,
  //   // component: Earnings,
  //   subLinks: [
  //     {
  //       id: 41,
  //       title: "Earnings",
  //       key: "earnings",
  //       routeLink: URLS.Earnings,
  //       component: Earnings,
  //     },
  //     {
  //       id: 42,
  //       title: "Settlements",
  //       key: "settlements",
  //       routeLink: URLS.Settlements,
  //       component: Settlements,
  //     },
  //   ],
  // },
];
const Dashboard = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const { fetchingCurrencyList, currencyListPayload } = useSelector(
    ({ cards }: any) => cards
  );

  useEffect(() => {
    if (!currencyListPayload) {
      // dispatch(fetchCurrencyList());
    }
    if (history.location.pathname === "/cards-service") {
      history.push(URLS.Overview);
    } else {
      const path = history.location.pathname.split("/");
      const index = path.indexOf("app");
      const urlPath = path.splice(index, path.length);
      history.push(`/${urlPath.join("/")}`);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="service-details">
      <BackButton label="Cards" />
      <section
        className="content-section sp-pt--24"
        data-testid="content-section"
      >
        <CustomTab data={tabItems}>
          <Switch>
            {tabItems.map(
              ({ component: Component, key, routeLink, subLinks }) => {
                if (Component)
                  return (
                    <Route
                      key={key}
                      exact
                      path={routeLink}
                      render={() => (
                        <Component
                          currencyList={currencyListPayload?.data?.responseData}
                          fetchingCurrencyList={fetchingCurrencyList}
                        />
                      )}
                    />
                  );
                if (subLinks && subLinks.length > 0)
                  return subLinks?.map(
                    ({ component: Component, ...link }) =>
                      Component && (
                        <Route
                          key={link.key}
                          exact
                          path={link.routeLink}
                          render={() => (
                            <Component
                              currencyList={
                                currencyListPayload?.data?.responseData
                              }
                              fetchingCurrencyList={fetchingCurrencyList}
                            />
                          )}
                        />
                      )
                  );
                return null;
              }
            )}
          </Switch>
        </CustomTab>
      </section>
    </div>
  );
};
export default Dashboard;
