import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { fetchCurrencyList, fetchReports } from "redux/actions/cards.action";
import StatsBox from "components/statsBox/statsBox";
import { Button, Typography } from "vgg-ui-react/dist/components/Core";
import swal from "@sweetalert/with-react";
import { DateRangePicker } from "react-date-range";
import SuccessModal from "shared/SuccessModal/SuccessModal";
import { ReactComponent as Totalcards } from "assets/images/totalcardIssued.svg";
import { ReactComponent as Totalblocked } from "assets/images/totalblockedcard.svg";
import { ReactComponent as Totalfrozen } from "assets/images/totalfrozencard.svg";
import getSymbolFromCurrency from "currency-symbol-map";
import CurrencyFormat from "react-currency-format";
import FilterDropdown from "shared/filterDropdown/FilterDropdown";
import OnboardCurrencyModal from "components/OnboardCurrencyModal/OnboardCurrencyModal";
import LineChartOverview from "components/LineChartOverview/LineChartOverview";
import { ICurrencyFilter } from "interfaces";
import { differenceInDays, format, subDays } from "date-fns";
import "./overview.scss";

const Overview = ({
  currencyList,
  fetchingCurrencyList,
}: {
  currencyList: ICurrencyFilter[];
  fetchingCurrencyList: boolean;
}) => {
  const dispatch = useDispatch();
  const { fetchingReports, reportsPayload } = useSelector(
    ({ cards }: any) => cards
  );

  const [dateRange, setDateRange] = React.useState<any>({
    startDate: subDays(new Date(), 60),
    endDate: new Date(),
    key: "selection",
  });

  const [remount, setRemount] = React.useState(false);
  const [open, setOpen] = React.useState(false);
  const [showSuccess, setShowSuccess] = React.useState(false);
  const [selectedCurrency, setSelectedCurrency] = React.useState("");

  const statData = [
    {
      id: "totalTransactionVolume",
      title: "Total Transaction Value",
      stats: "9,098",
      color: "#EAFBF1",
      icon: <Totalcards />,
    },
    // {
    //   id: "availableBalance",
    //   title: "Available Balance",
    //   stats: "7,700",
    //   color: "#FDF2E7",
    //   icon: <Totalactive />,
    // },
    {
      id: "totalIssuedCards",
      title: "Number of Cards Issued",
      stats: "1000",
      color: "#E8EFFD",
      icon: <Totalblocked />,
    },
    {
      id: "totalCurrencies",
      title: "Number of Currencies",
      stats: "389",
      color: "#FCE9E9",
      icon: <Totalfrozen />,
    },
  ];
  // React.useEffect(() => {
  //   setDateRange({
  //     start: moment().subtract(60, "days"),
  //     end: moment(),
  //   });
  // }, []);

  React.useEffect(() => {
    dispatch(fetchCurrencyList());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  React.useEffect(() => {
    if (selectedCurrency && dateRange) {
      dispatch(
        fetchReports({
          currency: selectedCurrency,
          startDate: dateRange ? format(dateRange.startDate, "yyyy-MM-dd") : "",
          endDate: dateRange ? format(dateRange.endDate, "yyyy-MM-dd") : "",
        })
      );
    }
    if (remount) {
      setRemount(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dateRange, selectedCurrency]);

  const onSelect = (value: any) => {
    const { selection } = value;
    const dateCount = differenceInDays(selection.endDate, selection.startDate);
    if (dateCount > 60) {
      swal({
        text: "Kindly select range to be at most 60 days.",
        icon: "info",
        button: "Okay",
      });
    } else {
      setDateRange(selection);
    }
  };

  const renderContent = (range: any) => {
    return (
      <>
        {!range
          ? "Filter"
          : `${format(range.startDate, "Mo MMM, yyyy")} - ${format(
              range.endDate,
              "Mo MMM, yyyy"
            )}`}
      </>
    );
  };

  React.useEffect(() => {
    if (!fetchingCurrencyList && currencyList) {
      const curr = currencyList.filter(
        (v: ICurrencyFilter) => v.status.toLowerCase() === "approved"
      );
      if (curr.length > 0) {
        setSelectedCurrency(curr[0]?.currency);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fetchingCurrencyList, currencyList]);

  return (
    <div className="fade_in h-100">
      <div className="sp-py--32 w-100 d-flex align-items-center justify-content-between">
        <Typography
          typographyVariant="disp-heading"
          text="Analytics"
          className="font-weight-bold"
        />
        <div className="d-flex align-items-center justify-content-end">
          <FilterDropdown buttonContent={renderContent(dateRange)}>
            <>
              {!remount && (
                <DateRangePicker
                  ranges={[dateRange]}
                  onChange={onSelect}
                  className="w-100"
                  rangeColors={["#175fff"]}
                  showDateDisplay={false}
                />
              )}
            </>
          </FilterDropdown>
          <div className="ms-2">
            {selectedCurrency && (
              <FilterDropdown
                items={
                  currencyList?.filter(
                      (v: ICurrencyFilter) =>
                        v.status.toLowerCase() === "approved"
                    )
                    ?.map((v: any) => v.currency) || []
                }
                buttonContent={selectedCurrency}
                onItemClick={(curr) => setSelectedCurrency(curr)}
              />
            )}
          </div>
          <div className="ms-2">
            <Button
              label="Onboard Currency"
              background="blue"
              onClick={() => setOpen(true)}
            />
          </div>
        </div>
      </div>

      <div className="sp-pt--32 d-flex justify-content-between gap-3 align-items-center">
        {statData?.map((item: any, index) => (
          <StatsBox
            key={index}
            title={item.title}
            stat={
              item.id === "totalTransactionVolume" ||
              item.id === "availableBalance" ? (
                <CurrencyFormat
                  value={reportsPayload?.data?.responseData?.[item.id] || 0}
                  displayType="text"
                  thousandSeparator
                  prefix={getSymbolFromCurrency(selectedCurrency)}
                  renderText={(value: any) => value}
                  decimalScale={2}
                  fixedDecimalScale
                />
              ) : (
                reportsPayload?.data?.responseData?.[item.id] || 0
              )
            }
            color={item.color}
            icon={item.icon}
          />
        ))}
      </div>

      <div className="sp-pt--32">
        <LineChartOverview
          loading={fetchingReports}
          dataSource={reportsPayload?.data?.responseData?.transactions?.sort(
            (a: any, b: any) =>
              new Date(a.transactionDate).getTime() -
              new Date(b.transactionDate).getTime()
          )}
          currency={selectedCurrency}
          yKey="amount"
          xKey="transactionDate"
        />
      </div>

      {open && (
        <OnboardCurrencyModal
          setSelectedCurrency={setSelectedCurrency}
          open={open}
          toggle={() => setOpen(false)}
          onClosed={() => setOpen(false)}
          onSuccess={() => {
            setOpen(false);
            setShowSuccess(true);
          }}
          onboardedCurrencies={
            currencyList?.map((v: { currency: string }) => v.currency) || []
          }
        />
      )}
      {showSuccess && (
        <SuccessModal
          open={showSuccess}
          onClose={() => setShowSuccess(false)}
          btnText="Close"
          onDoneClick={() => setShowSuccess(false)}
        />
      )}
    </div>
  );
};

export default Overview;
