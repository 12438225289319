import React, { useEffect, useState } from "react";
import { Modal, ModalBody, ModalHeader } from "reactstrap";
import { useDispatch, useSelector } from "react-redux";
import jwt_decode from "jwt-decode";
import { fetchCurrencies, onboardCurrency } from "redux/actions/cards.action";
import { errorMessage } from "redux/actions/notification";
import OnboardCurrencyList from "./OnboardCurrencyList/OnboardCurrencyList";
import { getUserDetails } from "apiServices/user.service";
import CurrenciesHeader from "./CurrenciesHeader/CurrenciesHeader";

interface IOnboardModalProps {
  open: boolean;
  toggle: () => void;
  onClosed: () => void;
  onSuccess: () => void;
  setSelectedCurrency?: any;
  onboardedCurrencies?: string[];
}

const OnboardCurrencyModal: React.FC<IOnboardModalProps> = ({
  open,
  toggle,
  onClosed,
  onSuccess,
  setSelectedCurrency,
  onboardedCurrencies,
}) => {
  const [state, setState] = useState({
    List: [],
    MasterChecked: false,
    SelectedList: [],
  });
  let [submitStatus, setSubmitStatus] = useState(false);
  const [searchCountryValue, setSearchCountryValue] = useState("");
  const payload: any = getUserDetails()?.accessToken
    ? jwt_decode(getUserDetails()?.accessToken)
    : {};
  const {
    fetchingCurrencies,
    currenciesPayload,
    onboardingCurrency,
    onboardCurrencyPayload,
  } = useSelector(({ cards }: { cards: any }) => cards);

  const dispatch = useDispatch();

  useEffect(() => {
    if (payload.Client_Identifier) {
      dispatch(fetchCurrencies());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [payload.Client_Identifier]);

  useEffect(() => {
    if (!onboardingCurrency && onboardCurrencyPayload && submitStatus) {
      setSubmitStatus(false);
      if (onboardCurrencyPayload.data.requestSuccessful) {
        onSuccess();
        dispatch(fetchCurrencies());
      } else {
        dispatch(
          errorMessage(
            onboardCurrencyPayload?.data?.message ||
              "Unable to onboard currency"
          )
        );
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [onboardingCurrency, onboardCurrencyPayload]);

  useEffect(() => {
    if (currenciesPayload?.data?.responseData?.items) {
      setState((pre: any) => ({
        ...pre,
        currencies: currenciesPayload?.data?.responseData?.items,
      }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currenciesPayload?.data?.responseData?.items]);

  const handleOnboard = (currency: string) => {
    setSubmitStatus(true);
    setSelectedCurrency && setSelectedCurrency(currency);
    dispatch(onboardCurrency({ currency }));
  };

  const handleSearchCountry = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchCountryValue(e.target.value?.toLowerCase());
  };

  return (
    <Modal isOpen={open} onClosed={onClosed} backdrop="static">
      <ModalHeader toggle={toggle} style={{ borderBottom: "none" }}>
        <CurrenciesHeader loading={fetchingCurrencies} />
      </ModalHeader>
      <ModalBody style={{ padding: "0 1rem" }}>
        <div className="row">
          <OnboardCurrencyList
            loading={fetchingCurrencies || onboardingCurrency}
            onSearch={handleSearchCountry}
            currencies={currenciesPayload?.data?.responseData?.items}
            onSelect={(data: any) => handleOnboard(data)}
            selectedCurrency=""
            searchValue={searchCountryValue}
            onboardedCurrencies={onboardedCurrencies}
          />
        </div>
      </ModalBody>
    </Modal>
  );
};

export default OnboardCurrencyModal;
