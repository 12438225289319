export function maskNumber(
  value: number | string,
  startDigitsToShow: number = 4,
  maskCharacter: string = "*"
): string {
  if (!value) return "";
  // Convert the number to a string
  const numberStr = value.toString().slice(-7);

  // Calculate the number of digits to mask
  const digitsToMask = Math.max(0, numberStr.length - startDigitsToShow);

  // Create the masked string
  const maskedPortion = maskCharacter.repeat(digitsToMask);
  const visiblePortion = numberStr.slice(-startDigitsToShow);

  return `${maskedPortion} ${visiblePortion}`;
}
