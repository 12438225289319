import React from "react";

import ReactDOM from "react-dom";
import App from "./App";
import { BrowserRouter as Router } from "react-router-dom";
import { Provider } from "react-redux";

import { store } from "./redux/store";
import { setUserDetails } from "apiServices/user.service";

import "./index.scss";

declare var window: any;

type PropsRenderType = {
  containerId: string;
  history: string;
  userDetail: any;
  url: string;
};
type PropsUnmountType = {
  containerId: string;
};
window.renderCardsServiceApp = ({
  containerId,
  history,
  userDetail,
  url,
}: PropsRenderType) => {
  setUserDetails({ ...userDetail });
  ReactDOM.render(
    <Provider store={store}>
      <React.Suspense fallback={<div>Loading...</div>}>
        <Router>
          <App url={url} />
        </Router>
      </React.Suspense>
    </Provider>,
    document.getElementById(containerId)
  );
};

// unmount micro frontend function
window.unmountCardsServiceApp = ({ containerId }: PropsUnmountType) => {
  if (document.getElementById(containerId)) {
    ReactDOM.unmountComponentAtNode(
      document.getElementById(containerId) as Element
    );
  }
};

// Mount to root if it is not a micro frontend
if (!document.getElementById("CardsServiceApp-container")) {
  if (document.getElementById("root")) {
    ReactDOM.render(
      <Provider store={store}>
        <React.Suspense fallback={<div>Loading...</div>}>
          <Router>
            <App url="/" />
          </Router>
        </React.Suspense>
      </Provider>,
      document.getElementById("root")
    );
  }
}
