import React, { useState } from "react";
// import Button from "vgg-ui-react/dist/components/Core/Button";
// import Typography from "vgg-ui-react/dist/components/Core/Typography";
import { Button, Typography } from "vgg-ui-react/dist/components/Core";
import OnboardCurrencyModal from "components/OnboardCurrencyModal/OnboardCurrencyModal";
import BackButton from "components/backButton/BackButton";
import SuccessModal from "shared/SuccessModal/SuccessModal";
import "./onboardingPage.scss";
import { useDispatch } from "react-redux";
import { checkMerchantStatus } from "redux/actions/cards.action";

function OnboardingPage({ onboarded }: { onboarded: boolean }) {
  const dispatch = useDispatch();
  const [open, setOpen] = useState(false);
  const [showSuccess, setShowSuccess] = useState(false);
  const [selectedCurrency, setSelectedCurrency] = useState("");

  return (
    <div className="onboard-wrapper">
      <BackButton label="Cards" />
      <section className="card-section sp-pt--24 sp-pb--24 sp-pl--32 sp-pr--32">
        <div className="sp-mb--16">
          <Typography
            typographyVariant="section-title"
            text="Get Card Payments in seconds."
          />
        </div>
        <div className="w-sm-100 w-75">
          <p className="content-paragraph">
            BaseOne empowers you to create unlimited virtual accounts for your
            users and customers. Leverage the power of BaseOne to start creating
            virtual account numbers on a provider bank of your choice. Get
            started by choosing a provider bank.
          </p>
        </div>
        <div>
          <Button
            label={onboarded ? "Awaiting Approval" : "Onboard"}
            background="blue"
            size="md"
            disable={onboarded}
            className="onboard-btn"
            onClick={() => setOpen(true)}
          />
          <Button
            label={
              <span className="d-flex align-items-center">
                <span>Learn More</span>
                <i className="ri-arrow-right-up-line"></i>
              </span>
            }
            onClick={() =>
              window.open("https://developer.baseone.co/docs/", "_blank")
            }
            background="neutral"
            size="md"
            className="learn-more-btn"
          />
        </div>
        <p className="footnote">
          Note: BaseOne empowers you to create unlimited virtual accounts for
          your users and customers.
        </p>
        {open && (
          <OnboardCurrencyModal
            setSelectedCurrency={setSelectedCurrency}
            open={open}
            toggle={() => setOpen(false)}
            onClosed={() => setOpen(false)}
            onSuccess={() => {
              setOpen(false);
              setShowSuccess(true);
            }}
          />
        )}
        {showSuccess && (
          <SuccessModal
            open={showSuccess}
            selectedCurrency={selectedCurrency}
            onClose={() => setShowSuccess(false)}
            btnText="Close"
            onDoneClick={() => {
              setShowSuccess(() => {
                setOpen(() => false);
                dispatch(checkMerchantStatus());
                return false;
              });
            }}
          />
        )}
      </section>
    </div>
  );
}

export default OnboardingPage;
