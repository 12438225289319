import URLS from 'helpers/urls';
import { ICustomTabProps, ITabData } from 'interfaces';
import React, { useState, useEffect } from "react";
import { Link, useHistory } from "react-router-dom";
import {
  Col,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Nav,
  Navbar,
  NavItem,
  Row,
  TabContent,
  TabPane,
  UncontrolledDropdown,
} from "reactstrap";
import "./customTab.scss";
const CustomTab: React.FC<ICustomTabProps> = ({
  children,
  data,
  defaultActiveTab,
  onTabItemClick,
  useContent = true,
}) => {
  const history = useHistory();
  const [activeTab, setActiveTab] = useState({ linkKey: defaultActiveTab || data[0]?.key, sublinkTitle: "" });
  const handleLinkClick = (item: ITabData, sublinkTitle: string) => {
    onTabItemClick && onTabItemClick(item);
    setActiveTab({ linkKey: item.key, sublinkTitle });
  };

  useEffect(()=>{
    switch(history.location.pathname) {
      case URLS.Overview:
       setActiveTab({linkKey: 'overview', sublinkTitle: ""});
       break;
      case URLS.CARDS:
        setActiveTab({linkKey: 'cards', sublinkTitle: ""});
        break;
      case URLS.TRANSACTIONS:
        setActiveTab({linkKey: 'transactions', sublinkTitle: "transactions"});
        break;
      case URLS.ANALYTICS:
        setActiveTab({linkKey: 'transactions', sublinkTitle: "grafana"});
        break;
      case URLS.Earnings:
        setActiveTab({linkKey: 'earningsParent', sublinkTitle: "earnings"});
        break;
      default:
        setActiveTab({linkKey: 'earningsParent', sublinkTitle: "settlements"});
    }
  }, [history.location.pathname])

  return (
    <>
      <Navbar color="transparent" white expand="md">
        <Nav className='custom-tab-nav' navbar>
          {data?.map((item: ITabData, idx: number) => (
            <NavItem key={item.key}>
              {item?.subLinks && item?.subLinks.length > 0 ? (
                <UncontrolledDropdown setActiveFromChild className={`nav-link sp-navlink ${item.key === activeTab.linkKey ? "active" : ""
                  }`}>
                  <DropdownToggle tag="a" className={`nav-link p-0 ${item.key === activeTab.linkKey ? "activeSub" : ""
                    }`}>
                    {item.key === activeTab.linkKey ? activeTab.sublinkTitle : item.title}&nbsp;<i className="ri-arrow-down-s-line"></i>
                  </DropdownToggle>
                  <DropdownMenu>
                    {item.subLinks.map(v => (
                      <DropdownItem disabled={v.id === 32} key={v.key}>
                        <Link
                          className={`nav-link sp-navlink`}
                          to={v.routeLink}
                          onClick={() => handleLinkClick(item, v.title)}
                        >
                          {v.title}
                        </Link>
                      </DropdownItem>
                    ))
                    }
                  </DropdownMenu>
                </UncontrolledDropdown>
              ) : (

                <Link
                  className={`nav-link sp-navlink ${item.key === activeTab.linkKey ? "active" : ""
                    }`}
                  to={item.routeLink}
                  onClick={() => handleLinkClick(item, "")}
                >
                  {item.title}

                </Link>
              )}
            </NavItem>
          ))}

        </Nav>
      </Navbar>
      {useContent && (
        <TabContent activeTab={activeTab.linkKey}>
          {children ? (
            <>{children}</>
          ) : (
            <>
              {data?.map((item) => (
                <TabPane key={item.key} tabId={item.key}>
                  <Row>
                    <Col sm="12">{item?.content}</Col>
                  </Row>
                </TabPane>
              ))}
            </>
          )}
        </TabContent>
      )}
    </>
  );
};
export default CustomTab;
