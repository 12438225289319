import { Modal, ModalBody } from "reactstrap";
import { ReactComponent as CloseView } from "assets/images/closeView.svg";
import "./cardsModal.scss";
import VirtualCard from "components/VirtualCard/VirtualCard";
import { Button } from "vgg-ui-react/dist/components/Core";
import { useEffect, useState } from "react";
import Accordion from "components/Accordion/Accordion";
import AccountDetails from "components/AccountDetails/AccountDetails";
import TopupModal from "components/TopupModal/TopupModal";
import SetCardLimit from "components/SetCardLimit/SetCardLimit";
import { maskNumber } from "helpers/maskNumber";
import { useDispatch, useSelector } from "react-redux";
import { fetchCardById, getCardBalanceById } from "redux/actions/cards.action";
import SuccessModal from "shared/SuccessModal/SuccessModal";
import { getSuccessMsg } from "helpers/utils";

export const CardsModal: React.FC<{
  open: boolean;
  toggle: () => void;
  data: any;
}> = ({ open, toggle, data }) => {
  const dispatch = useDispatch();
  const [showCardLimitModal, setShowCardLimitModal] = useState(false);
  const [showTopupModal, setShowTopupModal] = useState(false);
  const [showSuccess, setShowSuccess] = useState({ open: false, key: "" });
  const { fetchingCard, fetchingCardBalance, cardPayload, cardBalancePayload } =
    useSelector(({ cards }: { cards: any }) => cards);

  useEffect(() => {
    if (data) {
      dispatch(fetchCardById(data.cardId));
      dispatch(getCardBalanceById(data.cardId));
    }
  }, [data?.id]);

  const accordionData = [
    {
      id: "1",
      title: "Account details",
      content: (
        <AccountDetails
          loading={fetchingCard || fetchingCardBalance}
          data={{
            ...data,
            ...cardPayload?.data.responseData,
            ...cardBalancePayload?.data.responseData,
          }}
        />
      ),
    },
    {
      id: "2",
      title: "KYC details",
      content: ``,
    },
  ];

  return (
    <>
      <Modal
        isOpen={open}
        toggle={toggle}
        backdrop="static"
        className="drawer fadeInRight w-480 animated ms-auto"
      >
        <ModalBody className="px-0">
          <div className="view-header px-4">
            <button className="btn p-0" onClick={toggle}>
              <CloseView />
            </button>
          </div>
          <div className="border-bottom my-4" />
          <div className="px-4 card-details">
            <div>
              <h2 className="card-details__heading mb-0">Card details</h2>
              <span className="card-details__desc">
                Information about generated card.
              </span>
            </div>

            <div className="my-4">
              <VirtualCard data={cardPayload?.data.responseData ?? data} />
            </div>

            <div className="d-flex flex-column">
              <Button
                label="Top-up Card"
                background="blue"
                fullWidth
                onClick={() => setShowTopupModal(true)}
              />
              <Button
                label="Set Card Limit"
                variant="outline"
                background="neutral"
                className="mt-2 outline-light"
                fullWidth
                onClick={() => setShowCardLimitModal(true)}
              />
            </div>
            <div>
              <Accordion data={accordionData} gap={1.5} />
            </div>
          </div>
        </ModalBody>
      </Modal>
      <TopupModal
        open={showTopupModal}
        toggle={() => setShowTopupModal(false)}
        cardId={data?.cardId}
        debitWallet={data?.currency}
        cardPan={maskNumber(data?.maskedPan)}
        onSuccess={(data) => {
          setShowSuccess({ open: true, key: "topup" });
        }}
      />
      <SetCardLimit
        open={showCardLimitModal}
        toggle={() => setShowCardLimitModal(false)}
        cardId={data?.cardId}
        limitEnabled={cardPayload?.data.responseData?.enableLimit}
        onSuccess={() => setShowSuccess({ open: true, key: "limit" })}
      />

      {showSuccess && (
        <SuccessModal
          open={showSuccess.open}
          onClose={() => setShowSuccess({ open: false, key: "" })}
          btnText="Close"
          description={getSuccessMsg(showSuccess.key)}
          onDoneClick={() => {
            setShowSuccess({ open: false, key: "" });
            setShowCardLimitModal(false);
            setShowTopupModal(false);
            dispatch(fetchCardById(data.cardId));
          }}
        />
      )}
    </>
  );
};

export default CardsModal;
