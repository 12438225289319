import React, { FormEvent, useEffect, useState } from "react";
import { Alert, Modal, ModalBody, ModalHeader } from "reactstrap";
import { Button, Typography } from "vgg-ui-react/dist/components/Core";
import { NumericFormat } from "react-number-format";
import { useDispatch, useSelector } from "react-redux";
import { setupLimit } from "redux/actions/cards.action";
import { successMessage } from "redux/actions/notification";
import "./setCardLimit.scss";
import Select from "react-select";
import SelectControl from "components/selectControl/SelectControl";
import { SpinLoader } from "components/loader/Loader";

const LimitCycleOptions = [
  {
    label: "Daily",
    value: "Daily",
  },
  {
    label: "Weekly",
    value: "Weekly",
  },
  {
    label: "Monthly",
    value: "Monthly",
  },
];

const SetCardLimit: React.FC<{
  open: boolean;
  toggle: () => void;
  cardId?: string;
  onSuccess: () => void;
  limitEnabled: boolean;
}> = ({ open, toggle, cardId, limitEnabled, onSuccess }) => {
  const dispatch = useDispatch();
  const [errorMsg, setErrorMsg] = useState("");
  const [submitted, setSubmitted] = useState(false);
  const [value, setValue] = useState({
    amount: "",
    limitCycle: "",
  });

  const { settingUpLimit, setupLimitPayload, setupLimitError } = useSelector(
    ({ cards }: { cards: any }) => cards
  );

  const onSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setSubmitted(true);
    dispatch(
      setupLimit({
        cardId,
        limitConfiguration: {
          limitCycle: value.limitCycle,
          limitAmount: value.amount,
        },
        limitAction: limitEnabled ? "UpdateLimit" : "EnableLimit",
      })
    );
  };

  useEffect(() => {
    let timeOut: any;
    if (errorMsg) {
      timeOut = setTimeout(() => {
        setErrorMsg("");
      }, 8000);
    }

    return () => {
      if (timeOut) {
        clearTimeout(timeOut);
      }
    };
  }, [errorMsg]);
  useEffect(() => {
    if (!settingUpLimit && submitted) {
      if (setupLimitError) {
        setErrorMsg(
          setupLimitError?.data?.message ??
            "Unable to set limit at this time. Please try again later"
        );
      } else if (setupLimitPayload?.data?.requestSuccessful) {
        toggle();
        dispatch(
          successMessage(
            setupLimitPayload?.data?.message ?? "Card created successfully"
          )
        );
        onSuccess();
      } else {
        setErrorMsg(
          setupLimitPayload?.data?.message ??
            "Unable to set limit at this time. Please try again later"
        );
      }
      setSubmitted(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    dispatch,
    submitted,
    toggle,
    settingUpLimit,
    setupLimitPayload,
    setupLimitError,
  ]);

  return (
    <Modal backdrop="static" isOpen={open} toggle={toggle} className="max-w-400" centered>
      <ModalHeader className="ms-0 align-items-start" toggle={toggle}>
        <div className="sp-modal__title">
          Card Limit
          <Typography
            typographyVariant="body-text2"
            text="Set a limit to be spent for this card"
            className="sp-modal__subtitle"
          />
        </div>
      </ModalHeader>

      <ModalBody className="sp-modal__body">
        <Alert
          color="danger"
          isOpen={!!errorMsg}
          toggle={() => setErrorMsg("")}
        >
          <span className="small">{errorMsg}</span>
        </Alert>

        <form onSubmit={onSubmit} className="mt-4">
          <div className="mb-3">
            <label htmlFor="amount" className="mb-0">
              Limit Cycle
            </label>
            <Select
              name="limitCycle"
              placeholder="Select"
              onChange={(e) =>
                setValue((prev) => ({ ...prev, limitCycle: e?.value ?? "" }))
              }
              // @ts-ignore
              controlClassName={`form-control ps-0 pt-0 pb-0`}
              components={{
                IndicatorSeparator: () => null,
                Control: SelectControl,
              }}
              options={LimitCycleOptions}
            />
          </div>
          <div>
            <label htmlFor="amount" className="mb-0">
              Amount
            </label>
            <NumericFormat
              name="amount"
              thousandSeparator
              onValueChange={(e) => {
                setValue((prev) => ({ ...prev, amount: e.value }));
              }}
              className="form-control w-100"
            />
          </div>
          <div>
            <Button
              type="submit"
              label={settingUpLimit ? <SpinLoader size="sm" /> : "Set Limit"}
              background="blue"
              className="w-100 mt-4"
              disabled={!value || settingUpLimit}
              isLoading={settingUpLimit}
            />
          </div>
        </form>
      </ModalBody>
    </Modal>
  );
};

export default SetCardLimit;
