import { format } from "date-fns";
import "./accountDetails.scss";
import Row from "./Row";
import getCurrencyByCode from "helpers/getCurrencyByCode";
import getSymbolFromCurrency from "currency-symbol-map";

const AccountDetails = ({ data, loading }: { data: any; loading: boolean }) => {
  if (loading)
    return (
      <div className="h_5 d-flex align-items-center justify-content-center text-sm">
        Loading...
      </div>
    );

    console.log({data})

  return (
    <div className="d-flex flex-column gap-4">
      <Row label="Currency" value={data?.currency ?? "-"} />
      <Row label="Account Type" value={data?.ledgerType ?? "-"} />
      <Row
        label="Card Balance"
        value={
          typeof data?.balance === "number"
            ? `${getSymbolFromCurrency(data?.currency)}${data?.balance}`
            : "-"
        }
      />
      <Row label="Card Type" value="Virtual" />
      <Row
        label="Created At"
        value={
          data?.createdAt ? data?.createdAt : "-"
        }
      />
      <Row label="Limit Cycle" value="None" />
      <Row
        label="Limit Amount"
        value={
          typeof data?.transactionLimit === "number"
            ? getCurrencyByCode(data?.currency) +
              data?.transactionLimit.toString()
            : "-"
        }
      />
    </div>
  );
};

export default AccountDetails;
