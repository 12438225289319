export default function getCurrencyByCode(code: string): string {
  switch (code) {
    case "NGN":
      return "₦";
    case "USD":
      return "$";
    case "KSH":
      return "KSh";
    default:
      return "N";
  }
}
