import { AxiosRequestConfig } from "axios";
import { userDetails } from "./user.service";

declare var window: any;

export const cardsApi =
  process.env.NODE_ENV === "development"
    ? "https://baseone-cardservice-apitest.azurewebsites.net"
    : `${window.env?.cardsBaseApiUrl}`;
export const cardsBaseApiUrl =
  process.env.NODE_ENV === "development"
    ? "https://baseone-cardservice-apitest.azurewebsites.net/api"
    : `${window.env?.cardsBaseApiUrl}/api`;
export const serviceManagerUrl =
  process.env.NODE_ENV === "development"
    ? "https://afservicemanagerclienttest.azurewebsites.net/api"
    : `${window.env?.baseApiUrl}/api`;
export const accountServiceUrl =
  process.env.NODE_ENV === "development"
    ? "https://accountserviceaggregator-apitest.azurewebsites.net/api"
    : `${window.env?.accountServiceApiUrl}/api`;
export const config: () => AxiosRequestConfig<any> | undefined = () => {
  if (userDetails()) {
    const bearerToken = `Bearer ${userDetails()?.accessToken}`;
    return { headers: { Authorization: bearerToken } };
  }
};
